@font-face {
    font-family: 'HafferMedium';
    src: url('../fonts/HafferTRIAL-Medium.woff2') format('woff2'),
        url('../fonts/HafferTRIAL-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Haffer';
    src: url('../fonts/HafferTRIAL-Regular.woff2') format('woff2'),
        url('../fonts/HafferTRIAL-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HafferLight';
    src: url('../fonts/HafferTRIAL-Light.woff2') format('woff2'),
        url('../fonts/HafferTRIAL-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'APK';
    src: url('../fonts/apk_monogami_regular-webfont.woff2') format('woff2'),
         url('../fonts/apk_monogami_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'APKProtocol';
    src: url('../fonts/APK-Protocol-Regular-Trial.woff2') format('woff2'),
        url('../fonts/APK-Protocol-Regular-Trial.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
