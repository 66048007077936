//       SUMMARY TABLE     
// =========================
// 1. Colors
// 2. Fonts
// 3. Animations
// 4. Values
// =========================


// 1. Colors
// =========================


// 2. Fonts
// =========================


// 3. Animations
// =========================
$smooth : cubic-bezier(0.15, 0.9, 0.34, 0.95);
$custom: cubic-bezier(.76,0,.24,1);

// 4. Values
// =========================



