//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

.legal-notice {
	color: #c6c6c6;
	font-size: 1.2rem;
	padding: 10vh 4rem 5vh 4rem;

	@include breakpoint(991px) {
		padding-left: 2rem;
		padding-right: 2rem;
		font-size: 1rem;
	}
}

.footer {
	height: 100vh;
	position: relative;
	padding-top: 5rem;

	@include breakpoint(991px) {
		height: auto;
	}

	&__hover {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		perspective: 2000px;
		pointer-events: none;
		z-index: 0;

		@include breakpoint(991px) {
			display: none;
		}

		p {
			font-size: 2.8rem;
			line-height: 1.2;
			opacity: 0;
			position: absolute;
			top: 15rem;
			left: 4rem;
			color: #9c9c9c;
			font-family: 'HafferLight';
			@include transform(rotateY(50deg) translateX(-60vw) translateZ(10px) scale(2));
		}

		&__img {
			height: calc(100% - 10rem);
			position: absolute;
			top: 5rem;
			left: 0;
			right: 0;
			margin: auto;
			width: 31vw;
			overflow: hidden;
			opacity: 0;
			@include transform(scale(1.4));
			transform-origin: right center;

			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		&__content {
			font-size: 1.6rem;
			line-height: 1.2;
			opacity: 0;
			position: absolute;
			color: #1e1e1e;
			top: 48%;
			left: 67vw;
			font-family: 'HafferLight';
			@include transform(rotateY(50deg) translateX(-5vw) translateZ(10px) scale(2));

			span {
				display: block;

				&:first-child {
					font-family: 'HafferMedium';
					margin-bottom: 1rem;
				}

				&:nth-child(2) {
					margin-left: 4rem;
					margin-bottom: 0.3rem;
				}
			}
		}
	}


	&__see {
		display: block;
		position: absolute;
		top: 5rem;
		left: 4rem;
		font-size: 2rem;
		color: #1e1e1e;
		line-height: 1;
		z-index: 2;

		@include breakpoint(991px) {
			left: 2rem;
		}

		&:hover + .footer__hover {
			.footer__hover__img {
				opacity: 1;
				@include transform(scale(1));
				@include transition(transform 1s $smooth, opacity 0.5s $smooth);
			}

			p,
			.footer__hover__content {
				opacity: 1;
				@include transition(transform 0.6s $smooth, opacity 0.5s $smooth);
				@include transform(rotateY(0deg) translateX(0vw) translateZ(0) scale(1));
			}

			.footer__hover__content {
				transition-delay: 0.2s;
			}
		}
	}

	&__grid {
		display: flex;
		padding-left: 50.7%;
		position: relative;
		z-index: 1;

		@include breakpoint(991px) {
			padding-top: 10vh;
			padding-left: 2rem;
			flex-wrap: wrap;
			padding-bottom: 20vh;
		}

		&__item {
			width: 470px;

			@include breakpoint(991px) {
				width: 50%;
			}

			h2 {
				font-size: 2.8rem;
				color: #1e1e1c;
				margin-bottom: 3rem;
				letter-spacing: -0.05em;

				@include breakpoint(991px) {
					font-size: 2rem;
				}
			}

			&__menu {
				&__item {
					margin-bottom: 0.5rem;

					a {
						color: #1e1e1e;
						font-family: 'HafferLight';
						font-size: 2rem;
						letter-spacing: -0.05em;

						@include breakpoint(991px) {
							font-size: 1.6rem;
						}
					}
				}
			}
		}
	}


	&__title {
		font-size: 14vw;
		font-family: 'Haffer';
		position: absolute;
		bottom: 2vw;
		left: 4rem;
		color: #1e1e1e;
		line-height: 0.8;
		letter-spacing: -0.03em;
		z-index: 1;

		@include breakpoint(991px) {
			bottom: 4vh;
			left: 2rem;
			font-size: 10rem;
		}
	}
}
