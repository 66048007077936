//       SUMMARY TABLE     
// =========================
// 1. Common
// 2. Cursor
// 3. Buttons
// =========================


// 1. Common
// =========================

.btn {
	color: #000;
	font-family: 'HafferLight';
	font-size: 2rem;
	background-color: #f5f5f5;
	border-radius: 5em;
	padding: 1.2rem 6rem;
	line-height: 1;

	@include breakpoint(1440px) {
		font-size: 1.8rem;
	}

	@include breakpoint(991px) {
		padding: 1rem 3rem;
	}

	&-secondary {
		-webkit-mask-image: -webkit-radial-gradient(white, black);
	  mask-image: radial-gradient(white, black);
		border: 1px solid #1e1e1e;
		color: #1e1e1e;
		font-family: 'HafferLight';
		font-size: 2rem;
		border-radius: 5em;
		padding: 1rem 5rem;
		overflow: hidden;
		letter-spacing: -0.04em;

		.cta__text {
			@include transition(color 0.6s $smooth);
		}

		@include breakpoint(991px) {
			&:active {
				background-color: #000;
				color: #fff;
			}
		}

		&:hover {
			.cta__text {
				color: #fff;
			}
		}
	}
}

.s-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; 
  z-index: 5;

  &.disabled {
  	display: none;
  }
}
  .s-scrollbar:hover {
    transform: scaleX(1.45); }
  .s-scrollbar:hover, .has-scroll-scrolling .s-scrollbar, .has-scroll-dragging .s-scrollbar {
    opacity: 1; }
  [data-scroll-direction="horizontal"] .s-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); }
    [data-scroll-direction="horizontal"] .s-scrollbar:hover {
      transform: scaleY(1.3); }

.s-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab; }
  .has-scroll-dragging .s-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  [data-scroll-direction="horizontal"] .s-scrollbar_thumb {
    right: auto;
    bottom: 0; }




// 2. Cursor
// =========================


.cursor {
	height: 4rem;
	width: 4rem;
	position: fixed;
	z-index: 500;
	pointer-events: none;
	top: -2rem;
	left: -2rem;
	will-change: transform;
	@include transform(translate(-100px, -100px));
	@include transition(transform 0.9s $smooth, opacity 0.3s $smooth);

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 50%;
		background-color: #f9f9f9;
		opacity: 0.6;
		@include transition(all 0.6s $smooth);
	}

	&.hover {
		&:after {
			background-color: #000;
			@include transform(scale(0.3));
			opacity: 0.8;
		}
	}
}






.tiles {
	display: flex;
	height: 50vw;

	&__item {
		flex: 0;
		height: 100%;
		overflow: hidden;

		img, video {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}

		&:nth-child(1) {
			img, video {
				@include transform(scale(1.3));
			}
			@include breakpoint(991px) {
				display: none;
			}
		}

		&:nth-child(2) {
			img, video {
				@include transform(scale(1.2));
			}
			@include breakpoint(991px) {
				display: none;
			}
		}

		&:nth-child(3) {
			img, video {
				@include transform(scale(1.1));
			}
			@include breakpoint(991px) {
				display: none;
			}
		}

		&:nth-child(4) {
			flex: 100%;
		}

		&:nth-child(5) {
			img, video {
				@include transform(scale(1.1));
			}
			@include breakpoint(991px) {
				display: none;
			}
		}

		&:nth-child(6) {
			img, video {
				@include transform(scale(1.2));
			}
			@include breakpoint(991px) {
				display: none;
			}
		}

		&:nth-child(7) {
			img, video {
				@include transform(scale(1.3));
			}
			@include breakpoint(991px) {
				display: none;
			}
		}
	}

	&.animate {
		.tiles__item {
			flex: 14.2857142857%;
			@include transition(all 2s $custom);

			img, video {
				@include transition(transform 2s $custom);
				@include transform(scale(1));
			}
		}
	}

	&.inverse {
		.tiles__item {
			flex: 14.2857142857%;

			&:nth-child(4) img,
			&:nth-child(4) video {
				@include transform(scale(1.4));
			}
		}

		&.animate {
			.tiles__item {
				flex: 0;

				&:nth-child(4) {
					flex: 100%;

					img, video {
						@include transform(scale(1.01));
					}
				}
			}
		}
	}
}


.legal {
	padding-top: 10vh;
	width: 120rem;
	max-width: 90%;
	margin: 0 auto;
	padding-bottom: 20vh;

	&__title {
		font-size: 4rem;
	}

	ul {
		padding-top: 4rem;
		margin-bottom: 6rem;

		li {
			&:first-child {
				border-top: 1px solid #dedede;
			}

			a {
				border-bottom: 1px solid #dedede;
				display: block;
				padding: 2rem 0;
				color: #999;
				font-size: 2rem;
				@include transition(color 0.4s $smooth);
				position: relative;

				&:after {
					content: '';
					display: block;
					height: 2rem;
					width: 2rem;
					position: absolute;
					top: 2.5rem;
					right: 0;
					background: url(../../img/arrow.svg) no-repeat center;
					background-size: contain;
					opacity: 0.5;
					@include transition(opacity 0.4s $smooth);
				}

				&:hover {
					color: #000;

					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}

