//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

* {
  box-sizing: border-box;
  outline: none;
}

body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  @include breakpoint(991px) {
    overflow-x: hidden;
    overflow-y: initial;
  }
}

@include breakpoint(991px) {
  html, body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: initial;
    position: relative;
  }

  html.body-home {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;

    body, .home__slider {
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--height);
    }
  }
}

img {
  max-width: 100%;
}

::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}



