//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================


@keyframes progress_slider {
	0% {
		@include transform(scaleX(0));
	}
	100% {
		@include transform(scaleX(1));
	}
}

.c-scrollbar {
	z-index: 3;
}

.slider {
	height: -webkit-fill-available;
	height: 100vh;
	width: 100%;
	position: relative;
	display: flex;
	background-color: #fff;

	@include breakpoint(991px) {
		height: var(--height);
	}

	.swiper-wrapper {
		transition-timing-function: $custom;
	}

	&__current {
		position: absolute;
		z-index: 2;
		font-size: 6vw;
		display: flex;
		line-height: 1;
		left: 1vw;
		top: 4vh;
		color: #fff;
		opacity: 0;
		visibility: hidden;
		@include transform(translateY(-100%));

		@include breakpoint(991px) {
			font-size: 6rem;
			top: 6rem;
			left: 1.8rem;
		}

		&__scroll {
			display: flex;
			flex-direction: column;
			@include transform(translateY(6vw));

			@include breakpoint(991px) {
				@include transform(translateY(6rem));
			}
		}

		.nb {
			height: 6vw;

			@include breakpoint(991px) {
				height: 6rem;
			}

			&.anim {
				overflow: hidden;
			}
		}
	}

	&__nav {
		position: absolute;
		background: transparent;
		appearance: none;
		border: none;
		height: 4rem;
		width: 4rem;
		top: 50%;
		z-index: 4;
		cursor: pointer;
		opacity: 0;
		padding: 0;
		visibility: hidden;

		svg {
			display: block;

			path {
				fill: #000;
				stroke: #000;
			}
		}

		&.next {
			right: 1vw;
			@include transform(translateY(-50%) translateX(100%));
		}

		&.prev {
			left: 1vw;
			@include transform(translateY(-50%) translateX(-100%));

			svg {
				@include transform(rotate(180deg));
			}
		}
	}

	&__left {
		overflow: hidden;
		width: 50%;
		height: 100%;
		position: relative;

		@include breakpoint(991px) {
			width: 100%;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: #000;
			will-change: transform;
			transform-origin: bottom center;
			z-index: 3;
		}
	}

	&__right {
		overflow: hidden;
		width: 50%;
		height: 100%;
		position: relative;

		&__title {
			position: absolute;
			color: #000;
			z-index: 2;
			bottom: 10%;
			right: 7%;
			font-size: 2rem;
			@include transform(rotate(-180deg) translateX(-2rem));
			@include transition(all 0.1s $smooth);
			opacity: 0;
			visibility: hidden;
			writing-mode: vertical-rl;

			@include breakpoint(1700px) {
				right: 6%;
			}

			@include breakpoint(1440px) {
				right: 5%;
			}

			&.show {
				opacity: 1;
				visibility: visible;
				@include transform(rotate(-180deg) translateX(0));
				@include transition(all 0.6s $custom);
			}
		}

		@include breakpoint(991px) {
			display: none;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: #000;
			will-change: transform;
			transform-origin: top center;
			z-index: 3;
		}

		.slider-imgs {
			&__item {
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
				@include transition(all .6s $smooth);

				img {
					@include transition(transform .6s $smooth);
				}
			}
		}


		.slider-imgs {
			.swiper-slide-active.hover {
				clip-path: polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%);

				img {
					@include transform(scale(1.1));
				}
			}
		}
	}

	.slider-imgs {
		height: 100%;

		&__item {
			height: 100%;

			img {
				display: block;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}

			.transition {
				position: absolute;
		    top: 21.8%;
		    left: 10%;
		    width: 36%;
		    height: 40%;
		    overflow: hidden;
		    opacity: 0;
   			@include transform(scale(1.7) rotate(10deg) translateY(100%));

    		img {
    			display: block;
    			height: 100%;
    			width: 100%;
				  object-fit: cover;
    			object-position: center;
    		}

    		&.show {
    			opacity: 1;
    			@include transform(scale(1) rotate(0) translateY(0));
    			@include transition(transform 1s $custom, opacity 0.2s $smooth 0.2s);
    		}
			}
		}
	}

	&__titles {
		height: 10vw;
		overflow: hidden;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));
		z-index: 2;
		color: #000;
		font-family: 'HafferLight';
		text-align: center;
		@include transition(all 0.6s $custom);

		@include breakpoint(1540px) {
			height: 11vw;
		}

		@include breakpoint(991px) {
			height: 14rem;
		}

		&.hide {
			opacity: 0;
			visibility: hidden;

			.slider__titles__item {
				@include transform(translateX(-100%));
			}
		}

		&__item {
			position: absolute;
			top: 0;
			pointer-events: none;
			left: 50%;
			height: 100%;
			@include transform(translateX(-50%));
			@include transition(all 0.6s $custom);
		}

		.btn-cta {
			position: absolute;
			bottom: 0;
			left: 50%;
			@include transform(translateX(-50%) translateY(150%));
			@include transition(all 0.6s $custom);
			// border-color: #fff;
			// color: #fff;

			// .cta__text {
			// 	color: #fff;
			// }

			// &:hover {
			// 	.cta__text {
			// 		color: #000;
			// 	}
			// }

			// .cta__anim {
			// 	background: #fff;
			// }

			&.animate {
				@include transform(translateX(-50%) translateY(0));
				pointer-events: initial;

				&:hover {
					@include transition(all 0.4s $custom);
				}
			}
		}

		h2 {
			font-size: 6vw;
			line-height: 1;
			letter-spacing: -0.02em;

			@include breakpoint(991px) {
				font-size: 4rem;
			}
		}
	}

	&__text {
		position: absolute;
		bottom: 0;
		left: 1vw;
		z-index: 5;
		pointer-events: none;

		.title-multiple {
			padding: 0;
			color: #fff;

			&__text {
				font-size: 26vw;
				height: 26vw;
			}
		}
	}

	&__content {
		width: 50%;
		height: 100%;
		position: absolute;
		pointer-events: none;
		top: 0;
		right: 0;
		z-index: 5;
		color: #000;
		overflow: hidden;
		background-color: #fff;
		opacity: 0;

		@include breakpoint(991px) {
			width: 100%;
			@include transition(opacity 0.5s $smooth);
			overflow-y: auto;
			overflow-x: hidden;
		}

		&__close {
			display: block;
			appearance: none;
			background: transparent;
			height: 5rem;
			width: 5rem;
			position: fixed;
			top: 2vw;
			right: 2vw;
			border: none;
			cursor: pointer;
			opacity: 0;
			visibility: hidden;
			@include transform(translate3d(10vw, 0, 0));
			@include transition(all 0.6s $custom);
			z-index: 2;

			@include breakpoint(991px) {
				top: 2rem;
			}
		}

		// &:before {
		// 	content: '';
		// 	display: block;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	height: 100%;
		// 	width: 100%;
		// 	background-color: #fff;
		// 	transform-origin: right center;
		// 	@include transform(scaleX(0) translateZ(0));
		// 	@include transition(transform 0.8s $custom);
		// 	will-change: transform;
		// }

		&.show {
			pointer-events: initial;
			opacity: 1;

			// &:before {
			// 	@include transform(scaleX(1) translateZ(0));
			// }

			.slider__content__close {
				opacity: 1;
				visibility: visible;
				@include transition(all 0.6s $custom 1s);
				@include transform(translate3d(0, 0, 0));
			}
		}

		&__item {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 5vw;
			pointer-events: none!important;

			@include breakpoint(991px) {
				display: none;
			}

			&.animate {
				pointer-events: initial!important;

					@include breakpoint(991px) {
					display: block;
				}
			}

			&__title {
				overflow: hidden;
				margin-bottom: 4.6vh;

				@include breakpoint(1600px) {
					margin-bottom: 1.4vh;
				}

				@include breakpoint(1440px) {
					margin-bottom: 4vh;
				}
			}

			h2 {
				font-size: 6rem;
				opacity: 0;
				visibility: hidden;
				@include transform(translate3d(0, 20vh, 0));
				@include transition(all 0.6s $custom);

				&.animate {
					opacity: 1;
					visibility: visible;
					@include transform(translate3d(0, 0, 0));
				}

				@include breakpoint(991px) {
					font-size: 3rem;
				}
			}

			.items {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.item {
					flex: 45%;
					flex-grow: 0;
					margin-bottom: 10%;
					opacity: 0;
					visibility: hidden;
					@include transform(translate3d(0, 50vh, 0));
					@include transition(all 0.6s $custom);
					position: relative;

					@media screen and (min-width: 992px) {
						&:first-child {
							@include transition(all 0.3s $smooth);
							@include transform(translate3d(0, 0, 0));
						}
					}

					&__link {
						display: block;
						height: 100%;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}

					&__img {
						width: 100%;
						height: 40vh;
						background-color: #dedede;
						overflow: hidden;

						img {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
							@include transition(transform 1s $smooth);
							will-change: transform;
						}

						@include breakpoint(991px) {
							height: 25vh;
						}
					}

					&:hover {
						.item__img {
							img {
								@include transform(scale(1.1) rotate(1deg));
							}
						}
					}

					&__content {
						margin-top: 2rem;

						p {
							font-size: 1vw;
							color: #999;

							span {
								color: #1e1e1e;
							}

							@include breakpoint(991px) {
								font-size: 1.6rem;
							}
						}
					}
				}

				&.animate {
					.item {
						opacity: 1;
						visibility: visible;
						@include transform(translate3d(0, 0, 0));
						@include transition(all 1s $custom);

						@media screen and (min-width: 992px) {
							&:first-child {
								@include transition(none);
							}
						}

						@for $i from 2 to 20 {
							&:nth-child(#{$i}) {
								transition-delay: $i * 0.05s;
							}
						}
					}
				}
			}
		}
	}

	&__pagination {
		position: absolute;
		display: flex;
		z-index: 2;
		bottom: 5vh;
		pointer-events: none;
		left: 0;
		width: 100%;
		justify-content: center;
		@include transform(translateY(10vh));
		opacity: 0;
		visibility: hidden;

		&.hide {
			display: none;
		}

		&__item {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 3px;
			width: 4rem;
			position: relative;
			margin: 0 1rem;
			@include transition(width 0.6s $custom);
			will-change: width;

			.nb {
				position: absolute;
				bottom: 0;
				left: 0;
				font-family: 'HafferLight';
				font-size: 1.4rem;
				color: #1e1e1e;
				@include transform(translateY(130%));
				opacity: 0;
				visibility: hidden;
				@include transition(all 0.4s $smooth);
			}

			&:before {
				content: '';
				display: block;
				background-color: rgba(0, 0, 0, 0.1);
				height: 100%;
				width: 100%;
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
				@include transition(all 0.4s $custom);
			}

			&__progress {
				content: '';
				display: block;
				background-color: #010001;
				height: 100%;
				width: 100%;
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
				transform-origin: left center;
				@include transform(scaleX(0));
			}

			&.active {
				width: 8rem;

				.nb {
					opacity: 1;
					visibility: visible;
				}

				.slider__pagination__item__progress {
					animation: progress_slider 6s forwards linear;
				}
			}
		}
	}
}

.page.animate {
	.slider {
		&__left, &__right {
			&:after {
				@include transition(all 1s $custom);
				@include transform(scaleY(0));
			}
		}

		&__pagination {
			opacity: 1;
			visibility: visible;
			@include transform(translateY(0));
			@include transition(all 1s $custom 1.4s);
		}

		&__current {
			opacity: 1;
			visibility: visible;
			@include transform(translateY(0));
			@include transition(all 1s $custom 1.4s);
		}
	}
}


