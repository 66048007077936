//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

#menu-toggle {
  display: none;

  @include breakpoint(991px) {
    pointer-events: initial;
    display: block;
    appearance: none;
    background: transparent;
    border: none;
    height: 4rem;
    width: 4rem;
    position: relative;
    z-index: 20;
    @include transform(translateX(-1rem));

    &:before, &:after {
      content: '';
    }

    span, &:before, &:after {
      width: 1.6rem;
      height: 1px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      left: 1rem;
      @include transition(all 0.4s $smooth);
    }

    &:before {
      @include transform(translateY(-5px));
    }

    &:after {
      @include transform(translateY(5px));
    }

    &.close {
      span {
        opacity: 0;
        visibility: hidden;
      }

      &:before {
        @include transform(rotate(-45deg));
      }

      &:after {
        @include transform(rotate(45deg));
      }
    }
  }
}


.header {
  position: fixed;
  top: 2vh;
  width: 100%;
  z-index: 12;
  left: 0;
  padding: 0 1.6%;
  display: flex;
  align-items: center;
  @include transition(all 1s $custom);
  pointer-events: none;

  &__select {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    letter-spacing: -0.02em;
    color: #fff;
    font-size: 2rem;

    @include breakpoint(991px) {
      font-size: 1.6rem;
      z-index: 6;
    }
  }

  &__logo {
    color: #fff;
    font-family: 'Haffer';
    font-size: 2rem;
    @include transition(all 1s $custom);
    position: relative;
    line-height: 1.2;
    pointer-events: initial;
    transform-origin: left center;

    span {
      position: absolute;
      right: 0;
      top: 0;
      @include transform(translateX(200%));
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.6s $smooth);

      &.show {
        opacity: 1;
        visibility: visible;
        @include transform(translateX(110%));
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transform-origin: right center;
      @include transform(scaleX(0));
      @include transition(transform 0.6s $smooth);
      will-change: transform;
    }

    &:hover {
      &:after {
        @include transform(scaleX(1));
        transform-origin: left center;
      }
    }
  }

  &__nav {
    &__menu {
      display: flex;

      &__item {
        margin-right: 3rem;
        font-size: 1.6rem;

        &__link {
          color: #949494;
          letter-spacing: -0.02em;
          pointer-events: initial;
          @include transition(color 0.4s $smooth);

          &.active,
          &:hover {
            color: #ffffff;
            @include breakpoint(991px) {
              color: #000 !important;
            }
          }

        }
      }
    }

    @include breakpoint(991px) {
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      position: fixed;
      width: 100vw;
      height: var(--height);
      background-color: #fff;
      z-index: 5;
      top: 0;
      left: 0;
      padding: 10rem 0 0 2rem;
      @include transition(all 0.3s $smooth);

      &__menu {
        display: block;

        &__item {
          font-size: 2rem;
          margin-right: 0;
          margin-bottom: 2rem;
          opacity: 0;
          visibility: hidden;
          @include transform(translateY(2rem));
          @include transition(all 0.1s linear 0.3s);
        }
      }
    }
  }

  &.open {
    .header__nav {
      pointer-events: initial;
      opacity: 1;
      visibility: visible;

      &__menu {
        &__item {
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
          @include transition(all 0.8s $custom);

          &:nth-child(2) {
            transition-delay: 0.05s;
          }

          &:nth-child(3) {
            transition-delay: 0.1s;
          }
        }
      }
    }
  }

  @include breakpoint(991px) {
    padding: 0 2rem;
  }

  &.h-signup {
    top: 1.6rem;

    @include breakpoint(991px) {
      top: 10%;
    }

    .header__logo {
      color: #000;

      @include breakpoint(991px) {
        color: #fff;
        font-size: 3rem;
      }

      &:after {
        display: none;
      }
    }
  }

  &-home {
    a {
      @include transform(scale(1.2));
      opacity: 0;
    }
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    @include transform(translateY(-20vh));
  }

  .apply-btn {
    margin-left: auto;
    position: relative;
    border-radius: 25px;
    padding: .8rem 3rem;
    pointer-events: initial;
    line-height: 1;
    border: 1px solid #fff;
    color: #fff;
    font-size: 1.8rem;
    transform-origin: right center;
    @include transition(all 1s $smooth);

    &:hover {
      background-color: #fff;
      color: #000;
    }

    @include breakpoint(991px) {
      background-color: #f2f2f2;
      color: #000;
      border: none;
      padding: .4rem 1.4rem;
      font-size: 1.4rem;
      z-index: 6;
      position: relative;
    }
  }

  &.black {
    .header__select {
      color: #1e1e1c;
    }

    #menu-toggle {
      span, &:before, &:after {
        background-color: #000;
      }

    }

    .header__logo {
      color: #000;

      &:after {
        background-color: #000;
      }
    }

    .header__nav__menu__item__link {
      &.active {
        color: #000 !important;
      }

    }

    @include breakpoint(991px) {
      .header__nav__menu__item__link {
        &.active {
          color: #000 !important;
        }
      }
    }

    .apply-btn {
      color: #000;
      border-color: #000;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .apply-btn {
    margin-left: auto;
    position: relative;
    pointer-events: initial;
    line-height: 1;
    transform-origin: right center;
    @include transition(all 1s $custom);
    border-radius: 25px;
    background-color: #f2f2f2;
    color: #000;
    border: none;
    padding: 0.4rem 1rem;
    font-size: 1.4rem;
    z-index: 6;
  }

  &.black {
    .header__logo {
      color: #000;

      &:after {
        background-color: #000;
      }
    }

    .apply-btn {
      color: #000;
    }
  }
}












