//       SUMMARY TABLE     
// =========================
// 1. Global
// 2. Section Card
// 3. Section App
// =========================


// 1. Global
// =========================


.img {
	overflow: hidden;

	.img-anim {
		@include transform(scale(1.2));
		height: 100%;
		width: 100%;
	}
}



#loader {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	font-size: 3rem;

	.progress {
		display: block;
		height: 3px;
		width: 1440px;
		max-width: 60%;
		background-color: #dddddd;
		border-radius: 20px;
		position: absolute;
		top: 2vh;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 2;

		span {
			display: block;
			height: 3px;
			width: 100%;
			background-color: #1e1e1e;
			border-radius: 20px;
			@include transform(scaleX(0));
			transform-origin: left center;
			@include transition(transform 0.2s $smooth);
		}

		&.animate {
			opacity: 0;
			visibility: hidden;
			@include transition(all 1s $smooth 0.5s);
		}
	}

	&.loaded {
		@include transform(translateY(-150vh));
		@include transition(all .8s $custom .4s);

		span, p {
			opacity: 0;
			visibility: hidden;
			@include transition(all 0.4s $smooth);
		}
	}
}

#sequence-left {
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	display: none;

	@include breakpoint(991px) {
		position: fixed;
		height: var(--height);
	}
}
#sequence-right {
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	display: none;

	@include breakpoint(991px) {
		position: fixed;
		height: var(--height);
	}
}

#video-intro {
	display: block;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.drag {
	position: absolute;
	width: 16rem;
	height: 100%;
	left: 50%;
	margin-left: -8rem;
	z-index: 6;
	opacity: 0;
	@include transform(scale(0.4));
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	cursor: grab;

	.drag-me {
		position: absolute;
		left: 50%;
		margin-left: -5rem;
		top: 50%;
		@include transform(translateY(-50%));
		width: 10rem;
		@include transition(all 0.3s $smooth);
		-webkit-user-select: none;
		-webkit-touch-callout: none;

		&:before {
	    content: '';
	    position: absolute;
	    display: block;
	    width: 14rem;
	    height: 14rem;
	    box-sizing: border-box;
	    margin-left: -7rem;
	    top: 50%;
	    left: 50%;
	    margin-top: -7rem;
	    border-radius: 50%;
	    background-color: #fff;
	    pointer-events: none;
	    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	  }

		.circle {
			display: table;
			border-radius: 50%;
			background-color: #fff;
			height: 9rem;
			width: 9rem;
			margin: 0 auto;
			position: relative;
			@include transition(all 0.3s $smooth);

			@include breakpoint(991px) {
				height: 7rem;
				width: 7rem;
			}

			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				height: 1.8rem;
				width: 1.8rem;
				background: url(../../img/drag.svg) no-repeat center;
				background-size: contain;
				top: 50%;
				margin-top: -1rem;
				opacity: 0;
			}

			&:before {
				left: -3rem;
				@include transform(rotate(180deg));
			}

			&:after {
				right: -3rem;
			}
		}

		&:after {
			content: '';
			display: block;
			width: 9rem;
			height: 9rem;
			border: 10px solid rgba(255, 255, 255, 0.3);
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%) scale(0.6));
			opacity: 0;
			pointer-events: none;
			@include transition(all 0.3s $smooth);

			@include breakpoint(991px) {
				height: 7rem;
				width: 7rem;
			}
		}

		.shape {
			background: url(../../img/drag-arrows.svg) no-repeat center;
			background-size: contain;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 4.5rem;
			height: 4.5rem;
			margin: -2.25rem 0 0 -2.25rem;
			@include transition(all 0.3s $smooth);
		}

		&.dragging {
			cursor: grabbing;

			&:after {
				opacity: 1;
			}

			&:before {
				opacity: 0;
			}

			.shape {
				@include transform(scale(0.6));
			}

			.circle {
				@include transform(scale(0.6));

				&:before, &:after {
					opacity: 1;
				}
			}
		}
	}
}

.page {
	position: relative;
	z-index: 3;
}


.section {

	&__close {
		position: fixed;
		bottom: 0;
		right: 0;
		height: 90vh;
		width: 8vw;
		cursor: pointer;
		z-index: 2;
		filter: invert(1);

		&.black {
			filter: none;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			height: 1.6rem;
			width: 1.6rem;
			background: url(../../img/drag.svg) no-repeat center;
			background-size: contain;
			top: 50%;
			margin-top: -0.8rem;
			right: 3.2rem;
			filter: brightness(0);
			@include transform(rotate(180deg) translateX(-4rem));
			opacity: 0;
			@include transition(all 0.4s $smooth);
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 5rem;
			width: 5rem;
			border-radius: 50%;
			background-color: #000;
			right: -2.5rem;
			top: 50%;
			margin-top: -2.5rem;
			opacity: 0;
			@include transform(translateX(4rem));
			@include transition(all 0.4s $smooth);
		}

		&:hover {
			&:before {
				@include transform(rotate(180deg) translateX(0));
				opacity: 1;
			}

			&:after {
				@include transform(translateX(0));
				opacity: 1;
			}
		}

		&--right {
			right: auto;
			left: 0;
			filter: invert(1);

			&.black {
				filter: none;
			}

			&:before {
				right: auto;
				left: 3.2rem;
				@include transform(translateX(-4rem));
			}

			&:after {
				left: -2.5rem;
				right: auto;
				@include transform(translateX(-4rem));
			}

			&:hover {
				&:before, &:after {
					@include transform(translateX(0));
				}
			}
		}
	}

	&__head {
		height: 100vh;
		position: relative;
		text-align: center;

		&__img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: block;
			object-fit: cover;
			object-position: center;
			opacity: 0;
			visibility: hidden;

			img {
				height: 100%;
				width: 100%;
				display: block;
				object-fit: cover;
				object-position: center;
			}

			&.show {
				opacity: 1;
				visibility: visible;
				@include transition(all 0.6s $smooth);
			}
		}

		@include breakpoint(991px) {
			height: var(--height);
		}

		h1 {
			font-size: 7vw;
			font-family: 'APK';
			position: absolute;
			left: 0;
			width: 100%;
			text-align: center;

			@include breakpoint(1680px) {
				font-size: 9vw;
			}

			&.animate {
				& + h3 {
					opacity: 1;
					visibility: visible;
					@include transform(translateY(0));
					transition-delay: 1s;

					& + .scroll-indicator {
						opacity: 1;
						visibility: visible;
						@include transition(all 2s $smooth 2s);
					}
				}
			}
		}

		h3 {
			position: absolute;
			bottom: 10vh;
			left: 0;
			width: 100%;
			text-align: center;
			font-size: 2.4rem;
			letter-spacing: -0.04em;
			font-family: 'HafferLight';
			opacity: 0;
			visibility: hidden;
			span {
				font-size: 3rem;
			}
			@include transition(all 0.6s $custom);
			@include transform(translateY(5rem));

			@include breakpoint(991px) {
				padding: 0 5%;
				font-size: 1.6rem;
				span {
					font-size: 2rem;
				}
			}

		}

		.scroll-indicator {
			position: absolute;
			color: #fff;
			padding-left: 2.2rem;
			top: 50%;
			left: 1.6%;
			@include transform(translateY(-50%));
			font-size: 1.4rem;
			opacity: 0;
			visibility: hidden;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 2px;
				left: 0;
				height: 1.8rem;
				width: 1.8rem;
				background: url(../../img/arrow-circle.svg) no-repeat center;
				background-size: contain;
				@include transform(rotate(90deg));
			}
		}

	}
}



.s-link {
	bottom: 4vh;
	right: 1.6vw;
	color: #fff;
	z-index: 10;
	position: absolute;
	font-size: 1.8rem;
	font-family: 'HafferMedium';

	opacity: 0;
	visibility: hidden;
	@include transform(translateX(100%));
	@include transition(transform 1s $custom, color 0.3s $smooth, opacity 1s $custom, visibility 1s $custom);

	&.black {
		color: #000;
	}

	@include breakpoint(991px) {
		right: 2rem;
		position: fixed;
	}

	&.animate {
		opacity: 1;
		visibility: visible;
		@include transform(translateX(0));
	}
}


.home {
	&__slider {
		height: 100vh;
		display: flex;
		position: relative;
		user-select: none;
		z-index: 3;

		@include breakpoint(991px) {
			max-height: 100vh;
			height: var(--height);
			overflow: hidden;
		}

		& > .tip {
			bottom: 4vh;
			left: 1.6vw;
			color: #fff;
			z-index: 1;
		}

		&__item {
			overflow: hidden;
			position: relative;

			&.closed {
				cursor: pointer;
			}

			&.active {
				.section {
					opacity: 1;
					visibility: visible;
					pointer-events: initial;

					&__close {
						opacity: 1;
						visibility: visible;
						pointer-events: initial;
						@include transition(opacity 0.2s $smooth 1.2s, visibility 0.2s $smooth 1.2s);
					}
				}
			}

			.step1 {
				position: absolute;
				text-align: left;
				top: 12vh;
				left: 10%;
				width: 24rem;
				opacity: 0;
				@include transition(all 0.2s $smooth);

				@include breakpoint(991px) {
					display: none;
				}

				strong {
					font-family: 'HafferMedium';
					font-size: 3rem;
					display: block;
				}

				span {
					font-size: 1.8rem;
				}
			}

			.step2 {
				position: absolute;
				text-align: left;
				bottom: 10vh;
				right: 10%;
				width: 16rem;
				opacity: 0;
				@include transition(all 0.2s $smooth);

				@include breakpoint(991px) {
					display: none;
				}

				strong {
					font-family: 'HafferMedium';
					font-size: 3rem;
					display: block;
				}

				span {
					font-size: 1.8rem;
				}
			}

			&__content {
				position: absolute;
				width: 50vw;
				top: 0;
				height: 100%;
				text-align: center;
				padding-top: 23vh;
				overflow: hidden;
				z-index: 1;

				@include breakpoint(991px) {
					width: 28vw;
					padding-top: 35vh;

					.btn-secondary {
						font-size: 1.2rem;
						padding: 0.7rem 3rem;
					}
				}

				h2 {
					font-size: 2.6vw;
					color: #1e1e1e;
					letter-spacing: -0.03em;
					line-height: 1;
					margin-bottom: 1.4rem;
					opacity: 0;
					visibility: hidden;
					@include transform(scale(1.2));
					@include transition(all 1.2s $smooth 0.1s);

					@include breakpoint(1680px) {
						font-size: 4rem;
					}
				}

				h3 {
					font-family: 'HafferLight';
					font-size: .9vw;
					color: #1e1e1e;
					letter-spacing: -0.04em;
					margin-bottom: 4rem;
					opacity: 0;
					visibility: hidden;
					@include transform(scale(1.2));
					@include transition(all 1.2s $smooth 0.05s);

					@include breakpoint(1680px) {
						font-size: 1.8rem;
					}

					@include breakpoint(991px) {
						font-size: 1.4rem;
						margin-bottom: 2rem;
					}
				}

				div {
					opacity: 0;
					visibility: hidden;
					@include transform(translateY(2rem));
					@include transition(all 1s $smooth);
				}

				&.animate {
					h2, h3, div {
						opacity: 1;
						visibility: visible;
						@include transform(translateY(0));
					}

					h2 {
						transition-delay: 0s;
					}

					h3 {
						transition-delay: 0s;
					}

					div {
						transition-delay: 0.1s;
					}
				}
			}

			&.left {
				width: 50%;

				&.active {
					&:after {
						opacity: 0;
						@include transition(opacity 0.4s $smooth 1.2s);
					}
				}
	  		.home__slider__item__img,
	  		.home__slider__item__content {
					left: 0;
				}

				.home__slider__item__content {
					h2, h3, .btn-secondary {
						color: #fff;
					}

					.btn-secondary {
						border-color: #fff;

						.cta__text {
							color: #fff;
						}

						.cta__anim {
							background-color: #fff;
						}

						&:hover {
							.cta__text {
								color: #000;
							}
						}
					}
				}
			}

			&.right {
				flex: 1;

				.home__slider__item__img,
	  		.home__slider__item__content {
					right: 0;
				}
			}
		}
	}
}


.page--card {
	.section {
		color: #fff;

		&__head {
			h1 {
				top: 4vh;
				span:nth-child(3) {
					font-family: 'APKProtocol';
				}	

				@include breakpoint(991px) {
					top: 10vh;
				} 
			}
		}
	}
}

.page--app {
	.section {
		&__head {
			h1 {
				bottom: 10vh;

				@include breakpoint(991px) {
					bottom: 20vh;
					color: #fff;
				}
			}

			h3 {
				bottom: auto;
				top: 10vh;
			}
		}
	}
}



// 2. Section Card
// =========================

.section-card {
	background-color: #fff;

	&__footer {
		background-color: #fff;
		padding: 6rem 0 20vh 4vw;
		display: flex;

		@include breakpoint(991px) {
			padding-top: 10rem;
			flex-wrap: wrap;
			padding-left: 2rem;
		}

		.img {
			background-color: #e1e1e1;
			height: 26vw;
			width: 26vw;
			margin-right: 20vw;

			video {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}

			@include breakpoint(991px) {
				height: 65vw;
    		width: 65vw;
    		margin-right: 0;
			}
		}

		.content {
			padding-top: 35vh;
			width: 29vw;
			color: #0d0e13;

			a {
				color: #000;
				position: relative;

				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					height: 1px;
					width: 100%;
					background-color: #000;
					@include transition(transform 0.5s $smooth);
					transform-origin: left center;
				}

				&:hover {
					&:after {
						@include transform(scaleX(0));
						transform-origin: right center;
					}
				}
			}

			@include breakpoint(991px) {
				width: 100%;
    		padding-top: 5vh;
			}

			h2 {
				font-size: 7vw;
				margin: 0 0 6rem 0;
				line-height: 1.2;
				letter-spacing: -0.02em;

				@include breakpoint(991px) {
					font-size: 9rem;
				}
			}

			p {
				font-size: 2.4rem;
				letter-spacing: -0.02em;
				margin-bottom: 6rem;
				width: 80%;

				@include breakpoint(1440px) {
					font-size: 1.6rem;
				}
			}

			span {
				display: block;
				font-size: 1.6rem;
				line-height: 1;
				letter-spacing: -0.02em;
			}
		}
	}

	&__footer-grid {
		background-color: #fff;
		display: flex;
		align-items: flex-end;
		padding-left: 18vw;

		@include breakpoint(991px) {
			padding: 0 2rem;
			flex-wrap: wrap;
		}

		.img {
			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}

			&:nth-child(3) {
				width: 8.8vw;
				height: 35.5vh;
				margin-left: -1px;

				@include breakpoint(991px) {
					width: 20vw;
					height: 26vh;
				}
			}

			&:nth-child(2) {
				width: 26vw;
				height: 80vh;

				@include breakpoint(991px) {
					width: 70vw;
					height: 58vh;
				}

				img {
					object-position: top center;
				}
			}
		}

		.content {
			margin-right: 4vw;
			color: #1e1e1e;

			@include breakpoint(991px) {
				margin: 0 0 4rem 0;
				width: 100%;
			}

			span {
				display: block;
				font-family: 'HafferMedium';
				font-size: 1.4rem;
				letter-spacing: -0.02em;
				margin-bottom: 2rem;
			}

			p {
				font-size: 1.8rem;
				letter-spacing: -0.02em;
				width: 28rem;
			}
		}
	}


	&__feature-block {
		background-color: #fff;
		height: 100vh;
		position: relative;
		padding: 12vh 0 0 51vw;
		color: #1e1e1c;

		@include breakpoint(991px) {
			padding: 0;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
		}

		h2 {
			letter-spacing: -0.02em;
			font-size: 4vw;
			line-height: 1.2;

			@include breakpoint(991px) {
				order: 2;
				font-size: 3.4rem;
				width: 90%;
				margin: 2rem auto 0 auto;
			}
		}

		p {
			font-family: 'HafferLight';
			letter-spacing: -0.05em;
			font-size: 3rem;
			line-height: 1;
			color: #1e1e1c;
			width: 54rem;
			max-width: 100%;

			@include breakpoint(1440px) {
				font-size: 2rem;
				line-height: 1.2;
			}

			@include breakpoint(991px) {
				order: 3;
				width: 90%;
				margin: 0 auto;
			}

			span {
				display: block;
			}
		}

		.img {
			video {
				transform-origin: right center;
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				@include transform(scaleX(3));
				@include transition(transform 2s $custom);
			}

			img {
				display: block;
				height: 100%;
				width: 100%;
				object-position: center;
				object-fit: cover;
			}
		}

		.img:first-child {
			height: 41vh;
    	width: 18vw;
    	position: absolute;
    	left: 32.6vw;
    	bottom: 1rem;
    	background-color: #f5f5f5;
    	transform-origin: left center;
    	@include transform(scale(0));
    	@include transition(all 1s $custom 0.4s);

    	@include breakpoint(991px) {
				position: relative;
				bottom: auto;
				left: auto;
				order: 1;
				width: 50vw;
				height: 30vh;
			}

    	&.is-inview {
    		@include transform(scale(1));
    	}
		}

		.img:nth-child(2) {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 32.7vw;
			clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
			@include transition(clip-path 2s $custom);

			@include breakpoint(991px) {
				position: relative;
				top: auto;
				left: auto;
				height: 50vh;
				order: 0;
				width: 49.9vw;
			}


			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				@include transform(scaleX(3));
				transform-origin: right center;
				@include transition(transform 2s $custom);
			}

			&.is-inview {
				clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);

				img, video {
					@include transform(scaleX(1));
				}
			}
		}
	}




	&__sequence {
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100vh;
		padding-left: 18vw;
		padding-right: 18vw;
		position: relative;
		color: #1e1e1c;

		.content {
			width: 16rem;
		}

		.tip {
			left: 1.6vw;
			bottom: 4vh;

			@include breakpoint(991px) {
				display: none;
			}

			i, span:after {
				filter: invert(1);
			}
		}

		@include breakpoint(1024px) {
			padding-left: 10vw;
			padding-right: 10vw;
		}

		@include breakpoint(991px) {
			height: 50vh;
			flex-wrap: wrap;
			padding: 0 2rem;
			justify-content: flex-start;
		}

		h3 {
			font-size: 3rem;
			line-height: 1.2;
			letter-spacing: -0.02em;
			width: 43rem;
			max-width: 100%;

			@include breakpoint(991px) {
				font-size: 2.6rem;
			}
		}

		.content {
			@include breakpoint(991px) {
				display: none;
			}

			h4 {
				font-family: 'HafferMedium';
				font-size: 1.8rem;
			}

			p {
				display: block;
				line-height: 1.3;
				font-size: 1.6rem;
			}
		}
	}

	&__summary {
		background-color: #fff;
		height: 24rem;
		padding-top: 4rem;
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #fff;
		}

		p {
			font-family: 'HafferLight';
	    font-size: 2rem;
	    letter-spacing: -0.02em;
	    line-height: 1.2;
	    color: #1e1e1e;
	    margin-left: 50vw;

	    @include breakpoint(991px) {
	    	margin: 0 auto;
	    	width: 90%;
	    }

	    span {
	    	display: block;
	    }
		}
	}

	&__scene {
		background-color: #fff;
		height: 100vh;
		padding: 0 1.6vw;
		margin-top: 10vh;

		@include breakpoint(991px) {
			height: 70vh;
			padding: 0 2rem;
		}

		.video {
			width: 100%;
			height: 84vh;
			background-color: #dedfdd;
			margin-top: 2rem;

			@include breakpoint(991px) {
				height: 65vh;
			}

			.tiles {
				height: 100%;
				width: 100%;
			}
		}

		h2 {
			font-size: 6vw;
			display: flex;
			justify-content: space-between;
			letter-spacing: -0.04em;
			line-height: 1;
			color: #1e1e1e;

			@include breakpoint(991px) {
				font-size: 2.4rem;
				justify-content: start;

				span:first-child {
					margin-right: 0.5rem;
				}
			}
		}
	}

	&__radial {
		height: 100vh;
		position: relative;

		@include breakpoint(991px) {
			height: 70vh;
		}

		p {
			font-size: 3vw;
			letter-spacing: -0.02em;
			color: #1e1e1e;
			position: absolute;
			top: 50%;
			z-index: 2;
			display: flex;

			@include breakpoint(991px) {
				font-size: 2rem;
			}

			span {
				display: block;
				opacity: 0;
				visibility: hidden;
				margin: 0 0.3vw;
			}

			&:first-child {
				left: 10vw;
				color: #fff;

				span {
					@include transform(translate3d(-1vw, -50%, 0));
					@include transition(all 1s $custom);
					transition-delay: 0.2s;

					&:nth-child(2) {
						transition-delay: 0.1s;
					}

					&:nth-child(3) {
						transition-delay: 0s;
					}
				}

			}

			&:nth-child(2) {
				right: 10.5vw;

				span {
					@include transform(translate3d(1vw, -50%, 0));
					@include transition(all 1s $custom 3s);

					&:nth-child(2) {
						transition-delay: 3.1s;
					}

					&:nth-child(3) {
						transition-delay: 3.2s;
					}
				}
			}
		}

		&.animate {
			p {
				span {
					opacity: 1;
					visibility: visible;
				}

				&:first-child {
					span {
						@include transform(translate3d(0, -50%, 0));
					}
				}

				&:nth-child(2) {
					span {
						@include transform(translate3d(0, -50%, 0));
					}
				}
			}
		}

		&.final {
			p:first-child {
				opacity: 0;
				visibility: hidden;
				@include transition(all 0.1s $smooth);
			}
		}

		video {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__feature {
		height: 100vh;
		padding: 6vh 0 0 15vw;
		position: relative;
		background-color: #000;

		&__content {
			position: relative;
			z-index: 2;
			width: 45rem;
			max-width: 100%;

			h3 {
				font-family: 'HafferMedium';
				font-weight: normal;
				font-size: 1.6rem;
				margin-bottom: 1rem;
			}

			p {
				line-height: 1.2;
				margin-bottom: 5rem;
			}

			p span {
				opacity: 0.48;
			}

			.benefits-link {
				position: relative;
				right: auto;
				bottom: auto;
				color: #fff;

				span:first-child {
					height: 2rem;
					width: 2rem;
					border-color: #fff;

					&:before {
						filter: invert(1);
					}
				}

				&:hover {
					span:first-child {
						background-color: #fff;

						&:before {
							filter: invert(0);
						}
					}
				}
			}
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 25%;
			height: 100%;
			width: 10vw;
			z-index: 1;
			background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);

			@include breakpoint(991px) {
				display: none;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 100%;
			background-color: #000;
		}

		@include breakpoint(991px) {
			height: 70vh;
			padding-left: 2rem;
		}

		video {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			position: absolute;
			top: 0;
			left: 0;
			@include transform(translateX(30%));

			@include breakpoint(991px) {
				@include transform(translateX(0));
				opacity: 0.4;
			}

		}

		&__title {
			position: absolute;
			bottom: 5vh;
			left: 15vw;
			z-index: 1;

			@include breakpoint(991px) {
				left: 2rem;
			}

			a {
				color: #fff;
				font-size: 2rem;
				font-family: 'HafferLight';
				letter-spacing: -0.02em;
				display: inline-flex;
				align-items: center;
				border: 1px solid #404040;
				border-radius: 20px;
				padding: 2px 10px;
				@include transition(all 0.3s $smooth);

				@include breakpoint(991px) {
					font-size: 1.4rem;
				}

				i {
					height: 1.1rem;
					width: 1.1rem;
					display: block;
					background: url(../../img/arrow.svg) no-repeat center;
					background-size: contain;
					margin-left: 1rem;
					@include transition(all 0.3s $smooth);
					filter: invert(1);
				}

				&:hover {
					background-color: #fff;
					color: #000;

					i {
						filter: invert(0);
					}
				}
			}
		}

		h2 {
			font-size: 6rem;
			font-family: 'Haffer';
			letter-spacing: -0.03em;
			line-height: 1;

			& > span {
				display: block;

				&:last-child {
					line-height: 1.2;
					@include transform(translateY(-0.5rem));
				}
			}

			@include breakpoint(991px) {
				font-size: 4rem;
			}
		}
	}

	&__type {
		background-color: #000;
		height: 100vh;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		h3 {
			position: absolute;
			top: 48vh;
			left: 10vw;
			font-size: 3.6vw;
			letter-spacing: -0.03em;

			@include breakpoint(991px) {
				font-size: 3rem;
				top: auto;
		    line-height: 1;
		    bottom: 10vh;
			}
		}

		video {
			display: block;
    	height: 70%;
		}
	}

	&__images {
		background-color: #fff;
		padding: 7.5vh 1.6vw 0 1.6vw;
		position: relative;
		color: #1e1e1e;

		@include breakpoint(991px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		h2 {
			font-size: 4vw;
			line-height: 1;
			letter-spacing: -0.02em;

			@include breakpoint(991px) {
				font-size: 4rem;
			}

			.split {
				display: block;
			}
		}

		.img {
			video, img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.wrap {
			padding: 5vh 0 0 0;

			@include breakpoint(991px) {
				padding-top: 10vh;
			}

			.item1 {
				display: flex;
				padding-left: 16.38vw;
				padding-bottom: .2vh;

				@include breakpoint(991px) {
					padding-left: 0;
					padding-bottom: 1.2vh;
				}

				.img {
					background-color: #c6c6c6;
					width: 31vw;
					height: 86vh;

					img {
						object-position: top center;
					}

					@include breakpoint(991px) {
						height: 40vh;
						width: 48vw;
					}
				}

				.slider-imgs {
					background-color: #f5f5f5;
					height: 56.2vh;
					width: 31vw;
					position: absolute;
					right: 1.6vw;
					top: 110vh;
					z-index: 1;
					cursor: pointer;
					overflow: hidden;
					@include transition(width 1s $custom);

					@include breakpoint(991px) {
						top: 84vh;
						width: 75vw;
						height: 40vh;
					}

					.sliderr {
						@include transition(transform 1s $custom);
						transform-origin: top center;
						will-change: transform;
					}

					&__item {
						width: 100%;
						height: 56.2vh;
						overflow: hidden;

						@include breakpoint(991px) {
							height: 40vh;
						}

						img {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
							@include transform(scale(1.7) rotate(10deg));
							@include transition(transform 1s $custom);
						}

						&.active {
							img {
								@include transform(scale(1));
							}
						}
					}
				}

				.content {
					display: flex;
					padding: 6vh 0 0 13.2vw;

					@include breakpoint(991px) {
						padding-left: 5vw;
						display: block;
						width: 40vw;
					}

					span {
						display: block;
						margin-right: 1.6vw;
						font-family: 'HafferLight';
						letter-spacing: -0.02em;
					}

					&__text {
						@include transform(translateY(-2px));
						width: 33rem;
						max-width: 100%;
					}

					p {
						font-size: 1.2vw;
						letter-spacing: -0.02em;
						line-height: 1.2;
						margin-bottom: 6vh;

						@include breakpoint(991px) {
							font-size: 1.4rem;
						}
					}

					a {
						color: #292934;
						font-size: 2rem;
						font-family: 'HafferLight';
						letter-spacing: -0.02em;
						display: inline-flex;
						align-items: center;
						border: 1px solid #292934;
						border-radius: 20px;
						padding: 2px 10px;
						@include transition(all 0.3s $smooth);

						@include breakpoint(991px) {
							font-size: 1.4rem;
						}

						i {
							height: 1.1rem;
							width: 1.1rem;
							display: block;
							background: url(../../img/arrow.svg) no-repeat center;
							background-size: contain;
							margin-left: 1rem;
							@include transition(all 0.3s $smooth);
						}

						&:hover {
							background-color: #292934;
							color: #fff;

							i {
								filter: invert(1);
							}
						}
					}
				}
			}

			.item2 {
				display: flex;
				padding-left: 16.38vw;
				align-items: flex-end;

				@include breakpoint(991px) {
					padding-left: 0;
					padding-top: 1vh;
					flex-wrap: wrap;

					& > div:first-child {
						width: 100%;
						order: 1;
						margin-top: 6rem;
					}
				}

				h3 {
					margin-bottom: 4rem;
				}

				p {
					width: 32vw;
					padding-bottom: 1.6vw;
					font-family: 'HafferLight';
					font-size: 2rem;
					letter-spacing: -0.02em;
					line-height: 1.2;
					padding-right: 17.5vw;

					@include breakpoint(991px) {
						width: 100%;
					}
				}

				.slider-imgs {
					width: 31vw;
					height: 86vh;
					cursor: pointer;
					overflow: hidden;
					@include transition(width 1s $custom);

					@include breakpoint(991px) {
						width: 80vw;
						height: 50vh;
					}

					.sliderr {
						@include transition(transform 1s $custom);
						transform-origin: top center;
						will-change: transform;
					}

					&__item {
						width: 100%;
						height: 86vh;
						overflow: hidden;

						@include breakpoint(991px) {
							height: 50vh;
						}

						img {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
							@include transform(scale(1.7) rotate(10deg));
							@include transition(transform 1s $custom);
						}

						&.active {
							img {
								@include transform(scale(1));
							}
						}
					}
				}

				.img {
					background-color: #c6c6c6;
					width: 31vw;
					height: 86vh;
				}
			}

			.item3 {
				display: flex;
				padding-left: 16.38vw;
				padding-bottom: 14vh;

				@include breakpoint(991px) {
					padding-left: 0;
					margin-left: -2rem;
					padding-top: 5vh;
					padding-bottom: 5vh;
					flex-wrap: wrap;
				}

				.img {
					background-color: #f5f5f5;
					height: 56.2vh;
					width: 31vw;

					@include breakpoint(991px) {
						width: 54vw;
						height: 30vh;
					}
				}

				.content {
					padding: 9.5vh 0 0 1.6vw;
					width: 28%;

					@include breakpoint(991px) {
						padding-left: 5vw;
						display: block;
						width: 40vw;
						padding-top: 2vh;
					}

					span {
						display: block;
						font-family: 'HafferMedium';
						letter-spacing: -0.02em;
					}

					p {
						margin: 5.5vh 0 2.5vh 0;
						font-size: 1.2vw;
						letter-spacing: -0.02em;
						line-height: 1.2;
						font-family: 'HafferLight';

						@include breakpoint(991px) {
							font-size: 1.4rem;
						}
					}

					a {
						color: #292934;
						font-size: 2rem;
						font-family: 'HafferLight';
						letter-spacing: -0.02em;
						display: inline-flex;
						align-items: center;
						border: 1px solid #292934;
						border-radius: 20px;
						padding: 2px 10px;
						@include transition(all 0.3s $smooth);

						@include breakpoint(991px) {
							font-size: 1.4rem;
						}

						i {
							height: 1.1rem;
							width: 1.1rem;
							display: block;
							background: url(../../img/arrow.svg) no-repeat center;
							background-size: contain;
							margin-left: 1rem;
							@include transition(all 0.3s $smooth);
						}

						&:hover {
							background-color: #292934;
							color: #fff;

							i {
								filter: invert(1);
							}
						}
					}


				}
			}
		}
	}

	&__bg {
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		height: 101vh;
		width: 100%;
		display: none;
	}

	&__grid-intro {
		position: absolute;
		height: 100vh;
		width: 100%;
		z-index: 1;
		top: 0;
		left: 0;

		.text {
			position: absolute;
			font-size: 1.6vw;
	    font-family: 'HafferLight';
	    top: 12%;
	    right: 6%;
	    line-height: 1.2;
	    z-index: 2;
	    color: #000;
	    opacity: 0;
	    visibility: hidden;
	    @include transform(translateY(6rem));
	    @include transition(all 1s $custom 0.3s);
		}

		&__img {
			position: absolute;
			height: 41vh;
			width: 18vw;
			background: url(../../img/select09.png);
			transform-origin: left center;
			@include transform(scale(0));
			@include transition(all 2s $custom);
	    bottom: 1rem;
	    left: 32.5%;
	    z-index: 1;
		}

		&.animate {
			.text {
				opacity: 1;
	    	visibility: visible;
	    	@include transform(translateY(0));
			}

			.section-card__grid-intro__img {
				@include transform(scale(1));
				transition-delay: 0.4s;
			}
		}
	}

	&__grid {
		background-color: #fff;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		height: 200vh;
		padding-bottom: 20vh;
		position: relative;

		.title-multiple {
			position: absolute;
			top: 30vh;
			left: 0;
			width: 100%;
			color: #000;
		}

		&__text {
			width: 30rem;
			position: absolute;
			top: 150vh;
			right: 20vw;
			color: #000;

			p {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}

		&__item {
			position: absolute;
			@include transition(all 1s $smooth);

			.wrap {
				background-color: #dedede;
				height: 100%;
				width: 100%;


				img, video {
					display: block;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}

				.slideshow {
					overflow: hidden;
					height: 100%;
			    width: 100%;
			    position: relative;

					img {
						position: absolute;
						@include transform(translate(-100%, 100%));
						@include transition(all 1s $custom 1s);
						will-change: transform;

						&.active {
							@include transform(translate(0%, 0%));
							@include transition(all 1s $custom);
							z-index: 2;
						}
					}
				}
			}

			&:first-child {
				width: 20vw;
				height: 50vh;
				top: 20vh;
				left: 10vw;
			}

			&:nth-child(2) {
				width: 30vw;
				height: 40vh;
				top: 10vh;
				left: 40vw;
			}

			&:nth-child(3) {
				width: 25vw;
				height: 45vh;
				top: 80vh;
				left: 50vw;
				z-index: 4;
			}

			&:nth-child(4) {
				width: 30vw;
				height: 18vw;
				top: 80vh;
				left: 10vw;
			}

			&:nth-child(5) {
				width: 50vw;
				height: 30vw;
				top: 130vh;
				left: 10vw;
			}
		}
	}


	&__video {
		height: 100vh;
		width: 100%;
		position: absolute;
		top: 0;
		clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
		z-index: 2;

		&.transition {
			@include transition(all 2s $custom);
		}

		&.animate {
			clip-path: polygon(0% 0%, 33% 0%, 33% 100%, 0% 100%)!important;

			video.main {
				@include transform(scaleX(0.4));
			}
		}

		h2 {
			color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
			font-size: 6rem;
			letter-spacing: -0.02em;
			line-height: 1;
			font-family: 'HafferLight';
			z-index: 1;
		}

		video.main {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			@include transition(all 2s $custom);
			transform-origin: left center;
		}

		.video-right {
			position: absolute;
			top: 0;
			right: 0;
			width: 50vw;
			height: 100vh;
			background-color: #dedede;
			z-index: 1;
			clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
			@include transition(clip-path 1.5s $custom);

			video, img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				@include transform(scaleX(3));
				@include transition(transform 2s $custom);
				transform-origin: left center;
			}

			&.animate {
				clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);

				video, img {
					@include transform(scaleX(1));
				}
			}
		}
	}

}



.section__toggle {
	display: block;
	appearance: none;
	border: none;
	background-color: #fff;
	border-radius: 50%;
	height: 4rem;
	width: 4rem;
	z-index: 2;
	position: absolute;
	top: 94vh;
	cursor: pointer;
	left: 1.6%;

	@include breakpoint(991px) {
		display: none;
	}
}









// 3. Section App
// =========================

.section-app {
	background-color: #fff;


	&__footer {
		background-color: #fff;
		padding: 6rem 0 20vh 18vw;
		display: flex;

		@include breakpoint(991px) {
			padding-top: 10rem;
			flex-wrap: wrap;
			padding-left: 2rem;
		}

		.img {
			background-color: #e1e1e1;
			height: 26vw;
			width: 26vw;
			margin-left: 20vw;

			video {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}

			@include breakpoint(991px) {
				height: 65vw;
    		width: 65vw;
    		margin-left: 0;
    		order: 0;
			}
		}

		.content {
			padding-top: 35vh;
			width: 29vw;
			color: #0d0e13;

			a {
				color: #000;
				position: relative;

				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					height: 1px;
					width: 100%;
					background-color: #000;
					@include transition(transform 0.5s $smooth);
					transform-origin: left center;
				}

				&:hover {
					&:after {
						@include transform(scaleX(0));
						transform-origin: right center;
					}
				}
			}

			@include breakpoint(991px) {
				width: 100%;
    		padding-top: 5vh;
    		order: 1;
			}

			h2 {
				font-size: 7vw;
				margin: 0 0 6rem 0;
				line-height: 1.2;
				letter-spacing: -0.02em;

				@include breakpoint(991px) {
					font-size: 9rem;
				}
			}

			p {
				font-size: 2.4rem;
				letter-spacing: -0.02em;
				margin-bottom: 6rem;
				width: 80%;

				@include breakpoint(1440px) {
					font-size: 1.6rem;
				}

				@include breakpoint(1440px) {
					font-size: 1.6rem;
				}
			}

			span {
				display: block;
				font-size: 1.6rem;
				line-height: 1;
				letter-spacing: -0.02em;
			}
		}
	}

	&__footer-grid {
		background-color: #fff;
		display: flex;
		align-items: flex-end;
		padding-left: 28vw;

		@include breakpoint(991px) {
			padding: 0 2rem;
			flex-wrap: wrap;
		}

		.img {
			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}

			&:first-child {
				width: 8.8vw;
				height: 35.5vh;

				@include breakpoint(991px) {
					width: 19vw;
					height: 26vh;
				}
			}

			&:nth-child(2) {
				width: 26vw;
				height: 80vh;

				@include breakpoint(991px) {
					width: 70vw;
					height: 58vh;
				}

				img {
					object-position: top center;
				}
			}
		}

		.content {
			margin-left: 4vw;
			color: #1e1e1e;

			@include breakpoint(991px) {
				margin: 4rem 0 0 0;
				width: 100%;
			}

			span {
				display: block;
				font-family: 'HafferMedium';
				font-size: 1.4rem;
				letter-spacing: -0.02em;
				margin-bottom: 2rem;
			}

			p {
				font-size: 1.8rem;
				letter-spacing: -0.02em;
				width: 28rem;
			}
		}
	}

	&__concierge {
		background-color: #fff;
		height: 100vh;
		padding: 48vh 0 0 18vw;

		h2 {
			color: #1e1e1c;
			font-size: 4vw;
			letter-spacing: -0.02em;
			line-height: 1.2;
			margin-bottom: 4rem;
		}

		p {
			font-family: 'HafferLight';
			letter-spacing: -0.05em;
			font-size: 3rem;
			line-height: 1;
			color: #1e1e1c;

			span {
				display: block;
			}
		}
	}

	&__radial {
		background-color: #fff;
		height: 100vh;
		position: relative;

		@include breakpoint(991px) {
			height: 70vh;
		}

		p {
			font-size: 3vw;
			letter-spacing: -0.02em;
			color: #1e1e1e;
			position: absolute;
			top: 50%;
			z-index: 2;
			display: flex;

			@include breakpoint(991px) {
				font-size: 2rem;
			}

			span {
				display: block;
				opacity: 0;
				visibility: hidden;
				margin: 0 0.3vw;
			}

			&:first-child {
				left: 10vw;

				span {
					@include transform(translate3d(-8vw, -50%, 0));
					@include transition(all 1s $custom);
					transition-delay: 0.2s;

					&:nth-child(2) {
						transition-delay: 0.1s;
					}

					&:nth-child(3) {
						transition-delay: 0s;
					}
				}
				
			}

			&:nth-child(2) {
				right: 10.5vw;

				span {
					@include transform(translate3d(8vw, -50%, 0));
					@include transition(all 1s $custom 3s);

					&:nth-child(2) {
						transition-delay: 3.1s;
					}

					&:nth-child(3) {
						transition-delay: 3.2s;
					}
				}
			}
		}

		&.animate {
			p {
				span {
					opacity: 1;
					visibility: visible;
				}

				&:first-child {
					span {
						@include transform(translate3d(0, -50%, 0));
					}
				}

				&:nth-child(2) {
					span {
						@include transform(translate3d(0, -50%, 0));
					}
				}
			}
		}

		&.final {
			p:first-child {
				opacity: 0;
				visibility: hidden;
				@include transition(all 0.1s $smooth);
			}
		}

		video {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: center;
		}
	}

	&__sequence {
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100vh;
		padding-left: 18vw;
		padding-right: 18vw;
		position: relative;

		.content {
			width: 16rem;
		}

		.tip {
			left: 1.6vw;
			bottom: 4vh;

			@include breakpoint(991px) {
				display: none;
			}

			i, span:after {
				filter: invert(1);
			}
		}

		@include breakpoint(1024px) {
			padding-left: 10vw;
			padding-right: 10vw;
		}

		@include breakpoint(991px) {
			height: 50vh;
			flex-wrap: wrap;
			padding: 0 2rem;
			justify-content: flex-start;
		}

		h3 {
			font-size: 3rem;
			line-height: 1.2;
			letter-spacing: -0.02em;
			width: 43rem;
			max-width: 100%;

			@include breakpoint(991px) {
				font-size: 2.6rem;
			}
		}

		.content {
			@include breakpoint(991px) {
				display: none;
			}

			h4 {
				font-family: 'HafferMedium';
				font-size: 1.8rem;
			}

			p {
				display: block;
				line-height: 1.3;
				font-size: 1.6rem;
			}
		}
	}

	&__feature {
		background-color: #fff;
		height: 100vh;
		position: relative;
		padding: 12vh 0 0 17vw;

		@include breakpoint(991px) {
			padding: 0;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
		}

		h2 {
			letter-spacing: -0.02em;
			font-size: 4vw;
			line-height: 1.2;

			@include breakpoint(991px) {
				order: 2;
				font-size: 3.4rem;
				width: 90%;
				margin: 2rem auto 0 auto;
			}
		}

		p {
			font-family: 'HafferLight';
			letter-spacing: -0.05em;
			font-size: 3rem;
			line-height: 1;
			color: #1e1e1c;
			width: 54rem;
			max-width: 100%;

			@include breakpoint(1680px) {
				font-size: 2rem;
			}

			@include breakpoint(991px) {
				order: 3;
				width: 90%;
				margin: 0 auto;
			}

			span {
				display: block;
			}
		}

		.img {
			video {
				transform-origin: left center;
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				@include transform(scaleX(3));
				@include transition(transform 2s $custom);
			}

			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.img:first-child {
			height: 41vh;
    	width: 18vw;
    	position: absolute;
    	right: 32.7vw;
    	bottom: 1rem;
    	background-color: #f5f5f5;
    	transform-origin: right center;
    	@include transform(scale(0));
    	@include transition(all 1s $custom 0.4s);

    	@include breakpoint(991px) {
				position: relative;
				bottom: auto;
				right: auto;
				order: 0;
				width: 50vw;
				height: 30vh;
			}

    	&.is-inview {
    		@include transform(scale(1));
    	}
		}

		.img:nth-child(2) {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 32.7vw;
			clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
			@include transition(clip-path 2s $custom);

			@include breakpoint(991px) {
				position: relative;
				top: auto;
				left: auto;
				height: 50vh;
				order: 1;
				width: 50vw;
			}

			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				@include transform(scaleX(3));
				transform-origin: left center;
				@include transition(transform 2s $custom);
			}

			&.is-inview {
				clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);

				img, video {
					@include transform(scaleX(1));
				}
			}
		}
	}

	&__phone {
		height: 100vh;
		width: 100%;
		position: relative;

		@include breakpoint(991px) {
			height: 80vh;
    	padding-bottom: 15vh;
		}

		.content {
			position: absolute;
			bottom: 5rem;
			left: 18vw;

			@include breakpoint(991px) {
				left: 2rem;
			}

			h3 {
				line-height: 1.1;
				letter-spacing: -0.05em;
				color: #1e1e1c;
				font-size: 1.2vw;
				margin-bottom: 7rem;
				width: 100%;

				@include breakpoint(1440px) {
					font-size: 2rem;
					margin-bottom: 3rem;
				}
			}
		}

		video {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}


	&__rotate {
		height: 100vh;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		.text {
			position: absolute;
			left: 1.6vw;
			bottom: 5vh;
			color: #1e1e1e;

			@include breakpoint(991px) {
				bottom: 0;
			}
			
			span {
				display: block;
				font-family: 'HafferMedium';
				font-size: 1.4rem;
				letter-spacing: -0.02em;
				margin-bottom: 2rem;
			}

			p {
				font-size: 1.8rem;
				letter-spacing: -0.02em;
				width: 28rem;

				@include breakpoint(991px) {
					width: 14rem;
					font-size: 1.4rem;
				}
			}
		}

		.content {
			position: absolute;
			top: 48vh;
			right: 10vw;

			@media screen and (min-width: 992px) {

				h3 {
					line-height: 1;
					margin-bottom: 2rem;
				}
			}

			@include breakpoint(991px) {
				top: auto;
		    bottom: 0;
			}

			a {
				color: #292934;
				font-size: 2rem;
				font-family: 'HafferLight';
				letter-spacing: -0.02em;
				display: inline-flex;
				align-items: center;
				border: 1px solid #292934;
				border-radius: 20px;
				padding: 2px 10px;
				@include transition(all 0.3s $smooth);

				@include breakpoint(991px) {
					font-size: 1.4rem;
				}

				i {
					height: 1.1rem;
					width: 1.1rem;
					display: block;
					background: url(../../img/arrow.svg) no-repeat center;
					background-size: contain;
					margin-left: 1rem;
					@include transition(all 0.3s $smooth);
				}

				&:hover {
					background-color: #292934;
					color: #fff;

					i {
						filter: invert(1);
					}
				}
			}
		}

		h3 {
			font-size: 3.6vw;
			letter-spacing: -0.03em;
			color: #1e1e1c;

			@include breakpoint(991px) {
				font-size: 3rem;
		    line-height: 1;
			}
		}

		video {
			display: block;
    	height: 70%;
		}
	}

	&__summary {
		background-color: #fff;
		height: 24rem;
		padding-top: 4rem;
		position: relative;

		p {
			font-family: 'HafferLight';
	    font-size: 2rem;
	    letter-spacing: -0.02em;
	    line-height: 1.2;
	    color: #1e1e1e;
	    margin-left: 50vw;

	    @include breakpoint(991px) {
	    	margin: 0 auto;
	    	width: 90%;
	    }

	    span {
	    	display: block;
	    }
		}
	}

	&__scene {
		background-color: #fff;
		height: 100vh;
		padding: 0 1.6vw;
		margin-top: 10vh;

		@include breakpoint(991px) {
			height: 50vh;
			padding: 0 2rem;
		}

		h2 {
			font-size: 6vw;
			display: flex;
			justify-content: space-between;
			letter-spacing: -0.04em;
			line-height: 1;
			color: #1e1e1e;

			@include breakpoint(991px) {
				font-size: 3rem;
				justify-content: start;

				span:first-child {
					margin-right: 0.5rem;
				}
			}
		}

		.video {
			width: 100%;
			height: 84vh;
			background-color: #dedfdd;
			margin-top: 2rem;

			.tiles {
				height: 100%;
				width: 100%;
			}

			@include breakpoint(991px) {
				height: 40vh;
			}
		}
	}

	&__images {
		background-color: #fff;
		position: relative;
		color: #1e1e1e;
		padding: 21vh 0 0 0;

		@include breakpoint(991px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		h2 {
			font-size: 4vw;
			line-height: 1;
			letter-spacing: -0.02em;
			padding-left: 50vw;

			@include breakpoint(991px) {
				font-size: 4rem;
			}

			.split {
				display: block;
			}
		}

		.wrap {
			padding: 25vh 0 0 0;

			@include breakpoint(991px) {
				padding-top: 10vh;
			}

			.item1 {
				display: flex;


				.content {
					display: flex;
					padding: 6vh 0 0 13.2vw;

					@include breakpoint(991px) {
						padding-left: 0;
						display: block;
						width: 40vw;
					}

					span {
						display: block;
						margin-right: 1.6vw;
						font-family: 'HafferLight';
						letter-spacing: -0.02em;
					}

					&__text {
						width: 47rem;
						max-width: 100%;
						@include transform(translateY(-2px));
					}

					p {
						font-size: 1.2vw;
				    letter-spacing: -0.02em;
				    line-height: 1.2;
				    margin-bottom: 6vh;

				    @include breakpoint(991px) {
							font-size: 1.4rem;
						}
					}

					a {
						color: #292934;
						font-size: 2rem;
						font-family: 'HafferLight';
						letter-spacing: -0.02em;
						display: inline-flex;
						align-items: center;
						border: 1px solid #292934;
						border-radius: 20px;
    				padding: 2px 10px;
    				@include transition(all 0.3s $smooth);

    				@include breakpoint(991px) {
    					font-size: 1.4rem;
    				}

						i {
							height: 1.1rem;
							width: 1.1rem;
							display: block;
							background: url(../../img/arrow.svg) no-repeat center;
							background-size: contain;
							margin-left: 1rem;
							@include transition(all 0.3s $smooth);
						}

						&:hover {
							background-color: #292934;
							color: #fff;

							i {
								filter: invert(1);
							}
						}
					}
				}

				.img1 {
					width: 31vw;
					height: 92vh;
					margin-left: 17.8vw;

					@include breakpoint(991px) {
						height: 40vh;
						width: 48vw;
						margin-left: 5vw;
					}

					img {
						display: block;
						height: 100%;
						width: 100%;
						object-fit: cover;
						object-position: top center;
					}
				}

				.slider-imgs {
					height: 56.2vh;
					z-index: 1;
					width: 31vw;
					z-index: 1;
					cursor: pointer;
					overflow: hidden;
					position: absolute;
					left: 1.6vw;
					top: 104vh;
					@include transition(width 1s $custom);

					.sliderr {
						@include transition(transform 1s $custom);
						transform-origin: top center;
						will-change: transform;
					}

					&__item {
						width: 100%;
						height: 56.2vh;
						overflow: hidden;

						@include breakpoint(991px) {
							height: 40vh;
						}

						img {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
							@include transform(scale(1.7) rotate(10deg));
							@include transition(transform 1s $custom);
						}

						&.active {
							img {
								@include transform(scale(1));
							}
						}
					}

					@include breakpoint(991px) {
						width: 65vw;
				    top: 82vh;
				    height: 40vh;
					}
				}
			}


			.item2 {
				display: flex;
				padding-left: 18vw;
				align-items: center;
				padding-top: 2vh;
				position: relative;
				z-index: 5;

				@include breakpoint(991px) {
					padding-top: 20vh;
			    padding-left: 0;
			    flex-wrap: wrap;
				}

				.slider-imgs {
					width: 31vw;
					height: 86vh;
					margin-right: 4rem;
					cursor: pointer;
					overflow: hidden;
					@include transition(width 1s $custom);

					.sliderr {
						@include transition(transform 1s $custom);
						transform-origin: top center;
						will-change: transform;
					}

					&__item {
						width: 100%;
						height: 86vh;
						overflow: hidden;
						position: relative;

						@include breakpoint(991px) {
							height: 40vh;
						}

						&__content {
							position: absolute;
							top: 10%;
							left: 0;
							width: 100%;
							text-align: center;
							z-index: 2;

							h3 {
								color: #fff;
								font-size: 4rem;
								letter-spacing: -0.02em;
							}

							p {
								color: #fff;
								margin-bottom: 4rem;
							}
						}

						img, video {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
							object-position: center;
							@include transform(scale(1.7) rotate(10deg));
							@include transition(transform 1s $custom);
						}

						&.active {
							img, video {
								@include transform(scale(1));
							}
						}
					}

					@include breakpoint(991px) {
						height: 40vh;
				    width: 60vw;
				    margin-right: 0px;
				    margin-left: 40vw;
					}
				}

				.content {
					width: 40vw;

					@include breakpoint(991px) {
						width: 100%;
						margin-top: 4rem;
					}

					h3 {
						font-size: 1.2vw;
				    letter-spacing: -0.02em;
				    line-height: 1.2;
				    margin-bottom: 4.5vh;

				    @include breakpoint(991px) {
				    	font-size: 2.4rem;
				    }
					}

					p {
						color: #423f3f;
						font-family: 'HafferLight';
						font-size: 2rem;
						width: 52rem;
						max-width: 100%;

						@include breakpoint(991px) {
							font-size: 1.4rem;
						}
					}
				}
			}

			.item3 {
				display: flex;
				padding-left: 18vw;
				padding-bottom: 14vh;

				@include breakpoint(991px) {
					padding-top: 10vh;
    			padding-left: 0;
				}

				.img {
					background-color: #f5f5f5;
					height: 56.2vh;
					margin-left: 6vw;
					width: 31vw;

					video {
						display: block;
						object-fit: cover;
						height: 100%;
						width: 100%;
						object-position: center;
					}

					@include breakpoint(991px) {
						width: 55vw;
    				height: 40vh;
					}
				}

				.content {
					padding: 9.5vh 0 0 0;
					width: 28%;

					@include breakpoint(991px) {
						width: 35vw;
					}

					span {
						display: block;
						font-family: 'HafferMedium';
						letter-spacing: -0.02em;
					}

					p {
						margin: 5.5vh 0 2.5vh 0;
						font-size: 1.2vw;
				    letter-spacing: -0.02em;
				    line-height: 1.2;
				    font-family: 'HafferLight';

				    @include breakpoint(991px) {
				    	font-size: 1.4rem;
				    }
					}

					a {
						color: #292934;
						font-size: 2rem;
						font-family: 'HafferLight';
						letter-spacing: -0.02em;
						display: inline-flex;
						align-items: center;
						border: 1px solid #292934;
						border-radius: 20px;
    				padding: 2px 10px;
    				@include transition(all 0.3s $smooth);

    				@include breakpoint(991px) {
    					font-size: 1.4rem;
    				}

						i {
							height: 1.1rem;
							width: 1.1rem;
							display: block;
							background: url(../../img/arrow.svg) no-repeat center;
							background-size: contain;
							margin-left: 1rem;
							@include transition(all 0.3s $smooth);
						}

						&:hover {
							background-color: #292934;
							color: #fff;

							i {
								filter: invert(1);
							}
						}
					}
				}

			}
		}
	}
}




@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

.img-expand {
	display: block;
	height: 100%;
	position: relative;
	overflow: hidden;

	&__btn {
		display: block;
		background-color: #fff;
		height: 5rem;
		width: 5rem;
		position: absolute;
		bottom: 4rem;
		right: 4rem;
		border-radius: 50%;
		@include transition(background-color 0.4s $smooth);

		&:before {
			content: '';
			position: relative;
			display: block;
			width: 200%;
			height: 200%;
			box-sizing: border-box;
			margin-left: -50%;
			margin-top: -50%;
			border-radius: 50%;
			background-color: #fff;
			animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
		}

		&:after {
			content: '';
			display: block;
			height: 35%;
			width: 35%;
			position: absolute;
			top: 50%;
			left: 50%;
			background: url(../../img/more.png) no-repeat center;
			background-size: contain;
			@include transition(all 0.4s $smooth);
			@include transform(translate(-50%, -50%));
		}

		@include breakpoint(991px) {
			height: 3rem;
			width: 3rem;
			bottom: 1rem;
			right: 1rem;
		}
	}

	img {
		@include transition(transform 1s $smooth);
		will-change: transform;
	}

	&:hover {
		img {
			@include transform(scale(1.1));
		}

		.img-expand__btn {
			background-color: #000;

			&:after {
				filter: invert(100%);
			}
		}
	}
}


.img-detail {
	position: fixed;
	z-index: 5;

	.select-details {
		position: absolute;
		left: 1.6vw;
		bottom: 25vh;
		color: #1e1e1e;
		opacity: 0;
		visibility: hidden;
		@include transform(translateX(-20%));
		@include transition(all 1s $custom);

		h3 {
			font-family: 'HafferMedium';
			font-size: 1.6rem;
		}

		p {
			font-size: 1.8rem;
			margin-top: 2rem;
			line-height: 1.2;
    	letter-spacing: -0.02em;
		}

		&.animate {
			opacity: 1;
			visibility: visible;
			@include transform(translateX(0));
			@include transition(all 1s $custom 0.4s);
		}
	}

	.close {
		position: absolute;
		top: 5rem;
		right: 8rem;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		@include transform(translateX(10vw));
		@include transition(all 0.6s $custom);

		@include breakpoint(991px) {
			top: 2rem;
    	right: 2rem;
		}

		button {
			cursor: pointer;
			display: block;
			width: 5rem;
			height: 5rem;
			border-radius: 50%;
			appearance: none;
			background: transparent;
			border: 1px solid #1e1e1e;
			@include transition(all 0.6s $smooth);

			&:after {
				content: '';
				display: block;
				height: 35%;
				width: 35%;
				position: absolute;
				top: 50%;
				left: 50%;
				background: url(../../img/more.png) no-repeat center;
				background-size: contain;
				@include transition(all 0.4s $smooth);
				@include transform(translate(-50%, -50%) rotate(45deg));
				@include transition(all 0.6s $smooth);
			}

			&:hover {
				background-color: #1e1e1e;

				&:after {
					filter: invert(100%);
				}
			}
		}
		
	}

	&#img-expand2,
	&#img-expand3,
	&.select {
		.close {
			button {
				border-color: #fff;

				&:after {
					filter: invert(100%);
				}

				&:hover {
					background-color: #fff;

					&:after {
						filter: none;
					}
				}
			}
		}
	}

	&__buttons {
		display: flex;
		position: absolute;
		right: 7vw;
		bottom: 6rem;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		@include transform(translateX(20vw));
		@include transition(all 0.4s $smooth);

		@include breakpoint(991px) {
			display: none;
		}

		button {
			background: transparent;
			appearance: none;
			height: 6rem;
			width: 6rem;
			display: flex;
			border-radius: 15px;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			color: #1e1e1c;
			border: 1px solid #1e1e1c;
			margin: 0 4px;
			font-size: 2rem;
			font-family: 'HafferLight';
			@include transition(all 0.3s $smooth);

			&:hover, &.active {
				background-color: #000;
				color: #fff;
				border-color: #000;
			}
		}
	}

	&.select .img-detail__buttons {
		button {
			border-color: #fff;
			color: #fff;

			&:hover, &.active {
				background-color: #fff;
				color: #000;
			}
		}
	}

	.back {
		position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    @include transition(all 0.3s $smooth!important);

    &.show {
    	opacity: 1;
    }
	}

	.scroller {
		height: 100%;
		@include transition(height 0.6s $smooth);

		&.select {
			img {
				transform-origin: bottom left;
				@include transform(scale(1.8));
			}
		}
	}

	&.expanded {
		.scroller {
			height: auto;

			@include breakpoint(991px) {
				height: 100%;
			}
		}

		.close {
			opacity: 1;
			visibility: visible;
			@include transform(translateX(0));
			transition-delay: 0.6s;
		}

		.img-detail__buttons {
			opacity: 1;
			visibility: visible;
			@include transform(translateX(0));
			@include transition(all 1s $custom 0.4s);
		}
	}
}

#img-expand1 {
	background-color: #eeeeee;
	width: 31vw;
  height: 92vh;
  overflow: hidden;

  @include breakpoint(991px) {
  	height: 40vh;
    width: 48vw;
  }

  img {
  	display: block;
  	margin: 0 auto;
  	width: 100%;
  	height: 100%;
  	object-fit: cover;
  	object-position: top center;
  	@include transition(all 0.6s cubic-bezier(0.04, 0.15, 0.24, 1));
  }

  &.expanded {
  	img {
  		height: auto;
  		width: 50%;
  		object-fit: initial;
  		@include transition(all 0.4s cubic-bezier(0.04, 0.15, 0.24, 1));

  		@include breakpoint(991px) {
  			width: 100%;
  			height: 100%;
  			object-fit: cover;
  		}
  	}
  }
}


#img-expand2 {
	background-color: #000;
	width: 26vw;
  height: 80vh;
  overflow: hidden;

  @include breakpoint(991px) {
  	height: 70vh;
    width: 58vw;
  }

  img {
  	display: block;
  	margin: 0 auto;
  	width: 100%;
  	height: 100%;
  	object-fit: cover;
  	object-position: top center;
  	@include transition(all 0.6s cubic-bezier(0.04, 0.15, 0.24, 1));
  }

  &.expanded {
  	img {
  		height: auto;
  		width: 50%;
  		object-fit: initial;
  		@include transition(all 0.4s cubic-bezier(0.04, 0.15, 0.24, 1));

  		@include breakpoint(991px) {
  			width: 100%;
  			height: 100%;
  			object-fit: cover;
  		}
  	}
  }
}


#img-expand3 {
	background-color: #000;
	width: 31vw;
  height: 86vh;
  overflow: hidden;

  @include breakpoint(991px) {
  	height: 40vh;
    width: 48vw;
  }

  img {
  	display: block;
  	margin: 0 auto;
  	width: 100%;
  	height: 100%;
  	object-fit: cover;
  	object-position: top center;
  	@include transition(all 0.6s cubic-bezier(0.04, 0.15, 0.24, 1));
  }

  &.expanded {
  	img {
  		height: auto;
  		width: 50%;
  		object-fit: initial;
  		@include transition(all 0.4s cubic-bezier(0.04, 0.15, 0.24, 1));

  		@include breakpoint(991px) {
  			width: 100%;
  			height: 100%;
  			object-fit: cover;
  		}
  	}
  }
}




.press {
	display: block;
	appearance: none;
	border: none;
	border: 1px solid #000;
	background-color: transparent;
	border-radius: 50%;
	height: 4rem;
	width: 4rem;
	z-index: 2;
	position: absolute;
	top: 94vh;
	cursor: pointer;
	left: 1.6%;
	opacity: 0;
	visibility: hidden;

	strong {
		font-size: 1.2rem;
		position: absolute;
		width: 10rem;
		top: 1.2rem;
    left: 3.4rem;
	}

	span {
		display: block;
		height: 4rem;
		width: 4rem;
		background-color: #000;
		border-radius: 50%;
		top: -1px;
		position: absolute;
		left: -1px;
		@include transform(scale(0));
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}
}



.motion {
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: 3;
	opacity: 0;
	visibility: hidden;

	&__progress {
		display: block;
		height: 3px;
		width: 1440px;
		max-width: 60%;
		background-color: #dddddd;
		border-radius: 20px;
		position: absolute;
		top: 4vh;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 2;

		span {
			display: block;
			height: 3px;
			width: 100%;
			background-color: #1e1e1e;
			border-radius: 20px;
			@include transform(scaleX(0));
			transform-origin: left center;
		}
	}

	&.show {
		opacity: 1;
		visibility: visible;

		.motion__progress span {
			@include transition(transform 1s linear);
		}
	}

	&__slide {
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		visibility: hidden;

		h2 {
			font-size: 12vw;
			letter-spacing: -0.06em;
			@include transition(all .8s $smooth);
		}

		&:last-child {
			z-index: 1;
		}

		.motion-grid {
			display: flex;
			position: relative;
	    height: 100%;
	    width: 100%;
	    flex-wrap: wrap;

			.item {
				clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
				@include transition(clip-path 0.6s $custom);

				img, video {
					display: block;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center;
				}

				&:first-child {
					width: 9vw;
					height: 35vh;
					margin-left: 27.2vw;
					margin-top: 12vh;
				}

				&:nth-child(2) {
					width: 26vw;
					height: 47vh;
					transition-delay: 0.05s;
				}

				&:nth-child(3) {
					width: 11.7vw;
					margin-left: 2.6vw;
					height: 5.8vh;
					transition-delay: 0.1s;
				}

				&:nth-child(4) {
					margin-left: 3.4vw;
					width: 26.3vw;
					height: 31vh;
					margin-top: 5.8vh;
					transition-delay: 0.15s;
				}

				&:nth-child(5) {
					margin-left: 38.6vw;
					width: 26vw;
					height: 26vw;
					margin-top: 5.8vh;
					transition-delay: 0.2s;
				}
			}
		}

		.title {
			position: absolute;
			top: 30vh;
			left: 0;
			width: 100%;
			padding: 0 26vw;
			display: flex;
			text-align: center;
			justify-content: space-between;

			span {
				font-size: 2.4rem;
			}
		}


		h3 {
			font-family: 'APK';
			font-size: 12vw;
			letter-spacing: -0.06em;
			@include transition(all .8s $smooth);
		}



		&.active {
			opacity: 1;
			visibility: visible;

			.motion-grid {
				.item {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
				}
			}

			h2, h3 {
				letter-spacing: -0.04em;
			}
		}
	}
}



.btn-secondary {
	position: relative;
}

.cta__anim {
	position: absolute;
  display: block;
  content: "";
  z-index: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  border-radius: 100%;
  background: #1e1e1e;
  transform: translate(-50%, -50%);
  @include transition(width 0.6s cubic-bezier(0.15, 0.9, 0.34, 0.95), padding-top 0.6s cubic-bezier(0.15, 0.9, 0.34, 0.95));

  @include breakpoint(991px) {
  	display: none;
  }
}

.cta__text {
	position: relative;
	z-index: 1;
	color: #1e1e1e;
}

.btn-secondary:hover {
	.cta__anim {
		width: calc(100% * 2);
		padding-top: calc(100% * 2);
	}
}




.preorder {
	background-color: #fff;
	padding: 10vh 1.6vw 0vh 1.6vw;
	display: flex;
	color: #1e1e1c;

	@include breakpoint(991px) {
		padding: 5vh 2rem 0 2rem;
		display: block;
	}

	&__title {
		h2 {
			font-family: 'HafferMedium';
			font-size: 2.8rem;
			line-height: 1.2;
		}

		h3 {
			font-size: 2.2rem;
			line-height: 1;
		}
	}

	&__content {
		margin-left: auto;
		width: 60rem;

		@include breakpoint(1300px) {
			margin-left: 20vw;
		}

		@include breakpoint(991px) {
			margin: 4rem 0 0 0;
			max-width: 100%;
		}

		& > p {
			font-family: 'HafferLight';
			font-size: 2rem;
			margin-bottom: 5rem;
			line-height: 1.2;
			width: 42rem;
			max-width: 100%;

			@include breakpoint(991px) {
				font-size: 1.7rem;
			}
		}

		.btn-secondary {
			font-size: 1.6rem;
		}

		.form {
			padding-bottom: 6rem;

			&__item {
				padding-left: 1rem;
				padding-right: 2rem;

				p {
					font-size: 2.6rem;
					font-family: 'HafferMedium';
					line-height: 1;
				}

				&:last-child {
					display: flex;
					justify-content: space-between;
					border-top: 2px solid #ececec;
					padding-top: 3rem;
					margin-top: 2rem;

					p {
						font-size: 2rem;
					}
				}

				.text {
					font-family: 'HafferLight';
					font-size: 1.6rem;
				}

				.circle {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid #000;
					height: 3rem;
					width: 3rem;
					border-radius: 50%;
					position: relative;

					&:after {
						content: '';
						display: block;
						height: 2.6rem;
						width: 2.6rem;
						background-color: #000;
						border-radius: 50%;
					}
				}
			}
		}

		.submit {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 1rem;

			& > span {
				font-family: 'HafferLight';
				font-size: 1.6rem;

				@include breakpoint(991px) {
					font-size: 1.2rem;
				}
			}
		}
	}
}







.sentence {
	padding-top: 0;
	width: 1440px;
	max-width: 60%;
	position: relative;

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    z-index: 1;

		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			transform-origin: 88% 9%;
			@include transform(scale(1.2));
		}
	}

	h2 {
		font-size: 10vw;
		line-height: 0.9;
    letter-spacing: -0.05em!important;
    margin-left: -0.7vw;

    .wrap {
    	overflow: hidden;
    	display: block;

    	& > span {
    		display: block;
    	}

    	&.left {
    		& > span {
    			@include transform(translate3d(-100%, 0, 0));
    		}
    	}

    	&.right {
    		& > span {
    			@include transform(translate3d(100%, 0, 0));
    			margin-left: -1%;
    		}
    	}

    	&.top {
    		& > span {
    			@include transform(translate3d(0, -100%, 0));
    		}
    	}

    	&.bottom {
    		font-family: 'APK';

    		& > span {
    			@include transform(translate3d(0, 100%, 0));
    		}
    	}
    }

    .mask {
    	display: inline-block;
    	clip-path: polygon(10% 0, 10% 0%, 10% 100%, 10% 100%);
    	width: 14vw;
    	height: 32.5vh;
    	margin-left: 11vw;
    	margin-right: 2rem;
    	vertical-align: middle;

    	img, video {
    		display: block;
    		height: 100%;
    		width: 100%;
    		object-fit: cover;
    		object-position: center;
    	}

    	& + .wrap {
				display: inline-block;
			}
    }

	}
}

.motion-app .sentence__img {

}

#motion-card .motion__slide.active .sentence h2 .wrap.bottom span span {
	font-family: 'APKProtocol';
}


.motion__slide.active {
	.sentence {

		&__img {
  		clip-path: polygon(65% 10%, 78% 10%, 78% 38%, 65% 38%);
  		@include transition(clip-path 1s $custom);

  		img {
  			@include transform(scale(0.4));
  			@include transition(transform 1s $custom);
  		}
  	}

		h2 {
			.wrap {
				&.left, &.right, &.top, &.bottom {
	    		span {
	    			@include transform(translate3d(0, 0, 0));
	    		}
	    	}

	    	&.left {
	    		span {
	    			@include transition(transform 1s $custom);
	    		}
	    	}

	    	&.top {
	    		span {
	    			@include transition(transform 1s $custom 0.1s);
	    		}
	    	}

	    	&.right {
	    		span {
	    			@include transition(transform 1s $custom 0.2s);
	    		}
	    	}

	    	&.bottom {
	    		span {
	    			@include transform(translate3d(0, -15%, 0));
	    			@include transition(transform 1s $custom 0.3s);
	    		}
	    	}
	    }

	    .mask {
	  		clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%);
	  		@include transition(clip-path 1s $custom 0.3s);
  		}
		}
	}
}






.tip {
	position: absolute;
	display: flex;
	align-items: center;
	font-family: 'HafferMedium';
	font-size: 1.4rem;
	opacity: 0;
	visibility: hidden;
	@include transform(translateX(-100%));

	i {
		display: block;
		background: url(../../img/arrow-circle.svg) no-repeat center;
		background-size: contain;
		height: 2rem;
		width: 2rem;
		margin-right: 0.8rem;
	}

	span {
		&:nth-child(2) {
			position: relative;
			padding-right: 4rem;

			&:after {
				content: '';
				display: block;
				height: 1.2rem;
				width: 7px;
				background: url(../../img/chevron.svg) no-repeat center;
				background-size: contain;
				right: 1.8rem;
				top: 0.5rem;
				position: absolute;
			}
		}
	}

	&.animate,
	&.is-inview {
		opacity: 1;
		visibility: visible;
		@include transform(translateX(0));
		@include transition(all 1s $custom);
	}
}

.slider-imgs__item .img-anim {
	height: 100%;
}

.benefits-link {
	color: #1e1e1c;
	font-size: 1.6rem;
	display: flex;
	margin-top: 3rem;
	align-items: center;
	position: absolute;
	bottom: 0;
	right: 4rem;
	pointer-events: initial;
	@include transform(translateY(calc(100% + 2rem)));

	span:first-child {
		height: 3rem;
		width: 3rem;
		display: block;
		border: 1px solid #1e1e1c;
		border-radius: 50%;
		margin-right: 1rem;
		position: relative;
		@include transition(all 0.3s $smooth);

		&:before {
			content: '';
			display: block;
			position: absolute;
			height: 0.8rem;
			width: 0.8rem;
			background: url(../../img/arrow.svg) no-repeat center;
			background-size: contain;
			@include transform(rotate(45deg));
			top: 50%;
			left: 50%;
			margin: -0.4rem 0 0 -0.4rem;
			@include transition(all 0.3s $smooth);
		}
	}

	span:last-child {
		letter-spacing: -0.02em;
	}

	&:hover {
		span:first-child {
			background-color: #1e1e1c;
			border-color: #1e1e1c;

			&:before {
				filter: invert(1);
			}
		}
	}

	@include breakpoint(991px) {
		position: relative;
		bottom: auto;
		margin-top: 0;
		margin-bottom: 8rem;
		right: auto;
	}
}
