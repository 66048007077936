
@include breakpoint(991px) {
	.form-wrap {
		overflow-x: hidden;
		overflow-y: scroll;
	}
}

.signup {
	background-color: #000;
	position: relative;
	color: #fff;

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	/* Firefox */
	input[type=number] {
	  -moz-appearance: textfield;
	}

	&:after {
		content: '';
		display: block;
		position: fixed;
		bottom: 0;
		left: 50%;
		height: 0.5vw;
		pointer-events: none;
		background-color: #000;
		width: 20%;
		@include transition(all 0.3s $smooth);

		@include breakpoint(991px) {
			display: none;
		}
	}

	&:before {
		content: '';
		display: block;
		position: fixed;
		top: 0;
		left: 50%;
		height: 0.5vw;
		pointer-events: none;
		background-color: #000;
		width: 20%;
		@include transition(all 0.3s $smooth);
		z-index: 1;

		@include breakpoint(991px) {
			display: none;
		}
	}

	&__imgs {
		overflow: hidden;
		width: 20%;
		margin-left: 50%;

		@include breakpoint(991px) {
			display: none;
		}

		&__item {
			margin: 0.5vw;
			max-width: calc(100% - 1vw);
			overflow: clip;
			height: 40vh;
			position: relative;

			opacity: 0;
			visibility: hidden;
			@include transform(translate3d(0, 20vh, 0));
			@include transition(all 1s $custom);

			&:nth-child(2) {
				height: 50vh;
				transition-delay: 0.1s;
			}

			&:nth-child(3) {
				transition-delay: 0.2s;
			}

			div {
				transform-origin: top center;
				position: relative;
				@include transform(translateY(-12vh));
				height: 100%;
				width: 100%;
			}
		}

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 150%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__video {
		position: fixed;
		display: flex;
		align-items: center;
		padding: 0.5vw 0 0.5vw 0.5vw;
		left: 0;
		top: 0;
		height: 100vh;
		width: 50%;

		@include breakpoint(991px) {
			width: 100%;
			height: 50vh;
			position: relative;
			left: auto;
			top: auto;
			padding: 50px 0 0 0;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #000;
			height: 100%;
			width: 100%;
			@include transition(transform .8s $custom);
			transform-origin: right center;
		}

		&__wrap {
			background-color: #222;
			height: 100%;
			width: 100%;
			@include transition(background-color 0.3s $smooth);

			video {
				display: block;
				object-fit: cover;
				object-position: center;
				height: 100%;
				width: 100%;
			}
		}
	}

	&__content {
		display: flex;
		align-items: center;
		position: fixed;
		padding: 0 5vw 5vw 5vw;
		height: 100vh;
		top: 0;
		right: 0;
		width: 30%;
		z-index: 5;
		overflow: auto;
		margin-bottom: 10px;

		& > div {
			& > * {
				opacity: 0;
				visibility: hidden;
				@include transform(translateY(15vh) translateZ(0));
				@include transition(all 1s $custom);
			}
		}

		@include breakpoint(991px) {
			position: relative;
			height: auto;
			width: 100%;
			top: auto;
			right: auto;
			padding: 5vh 5vw 15vh 5vw;
		}

		.custom-switch {
			margin-bottom: 4rem;

			@include breakpoint(991px) {
				margin-bottom: 2rem;
			}
		}

		.switch {
			position: absolute;
			top: 0.5vw;
			right: 2vw;

			@include breakpoint(991px) {
				font-size: 1.2rem;
				top: 2vh;
				right: 5vw;
			}
		}

		.wrap {
			width: 100%;

			& > * {
				opacity: 0;
				visibility: hidden;
				@include transform(translateY(15vh) translateZ(0));
				@include transition(all 1s $custom);
			}
		}

		.btn, .btn-secondary {
			margin-top: 0rem;
		}

		.confirmation, .email-verification {
			opacity: 0;
			visibility: hidden;
			display: none;
			&.show {
				opacity: 1;
				visibility: visible;
				display: block;
				@include transition(all 0.6s $smooth 0.6s);
			}
			&.hide {
				opacity: 0;
				visibility: hidden;
				display: none;
				@include transition(all 0.6s $smooth 0.6s);
			}
			.invite-link {
				margin: 2rem 0 0 0;
				cursor: pointer;
			}
			.invite-link-text {
				padding-left: 1rem;
				margin: 0 0 2rem 0;
				width: 100%;
			}
			.center {
				text-align: center;
				width: 100%;
			}
			h3.center {
				margin-top: 2rem;
			}
			.order-number {
				font-size: 5rem;
				margin-bottom: 2rem;
			}
		}


		.form {
			width: 100%;

			&.hide {
				opacity: 0;
				display: none;
				visibility: hidden;
				@include transition(all 0.6s $smooth);
			}

			h2 {
				font-size: 3rem;
				letter-spacing: -0.02em;
				margin-bottom: 1rem;
			}

			h3 {
				letter-spacing: -0.02em;
				font-family: 'HafferLight';
				font-size: 1.8rem;
			}

			.group-inputs {
				display: flex;
				flex-wrap: wrap;
			}

			.is-invalid .text-help {
				display: block;
				margin-bottom: 2rem;
				@include transform(translateY(-1rem));
				font-size: 1.2rem;
				padding-left: 2rem;
				color: #dedede;
			}

			&-control {
				background-color: transparent;
				display: block;
				appearance: none;
				border: none;
				border: 1px solid #282828;
				border-radius: 48px;
				color: #fff;
				margin-bottom: 2rem;
				padding: 1rem 2rem;
				font-family: 'Haffer';
				font-size: 1.6rem;
				@include transition(border-color 0.4s $smooth);
				width: 100%;

				&:focus {
					border-color: #fff;
				}

				&.phone, &.referer_code {
					& + span {
						display: block;
						margin-bottom: 2rem;
						@include transform(translateY(-1rem));
						font-size: 1.2rem;
						padding-left: 2rem;
						color: #dedede;
					}
				}

				&:required:invalid {
					border-color: #ce4747;
				}

				&:placeholder-shown:required:invalid {
					border-color: #282828;
				}
			}

			#submit {
				cursor: pointer;
			}

			&-group {
				width: 100%;
				overflow: hidden;
				@include transition(max-height 0.6s $smooth 0.2s, padding-bottom 0.6s $smooth 0.2s);

				&.disabled {
					pointer-events: none;
				}

				&__title {
					padding: 1rem 2rem;
					color: #a4a4a4;
					position: relative;
					margin-bottom: 2rem;
					@include transition(color 0.6s $smooth 0.2s);
					cursor: pointer;

					&:after {
						content: '';
						display: block;
						position: absolute;
						right: 2rem;
						top: 1.6rem;
						height: 1.4rem;
						width: 1.4rem;
						background: url(../../img/chevron-form.svg) no-repeat center;
						background-size: contain;
						@include transition(transform 0.6s $smooth 0.2s);
					}
				}

				&.active {
					padding-bottom: 2rem;
					@include transition(max-height 0.6s $smooth 0.4s, padding-bottom 0.6s $smooth 0.4s);

					.form-group__title {
						color: #fff;
						@include transition(color 0.6s $smooth 0.4s);

						&:after {
							@include transform(rotate(180deg));
							@include transition(transform 0.6s $smooth 0.4s);
						}
					}
				}
			}

			form {
				margin-top: 4rem;
			}
		}
	}

	&.animate {
		.signup__imgs__item {

			@include transform(translate3d(0, 0, 0));
			opacity: 1;
			visibility: visible;
		}

		.signup__content {
			& > div {
				& > * {
					opacity: 1;
					visibility: visible;
					@include transform(translateY(0) translateZ(0));

					@for $i from 1 to 10 {
						&:nth-child(#{$i}) {
							transition-delay: 0.1 + $i * 0.05s;
						}
					}
				}
			}
		}

		.signup__video {
			&:after {
				@include transform(scaleX(0));
			}
		}
	}
}

.privacy{
	background-color: #000;
	position: relative;
	color: #fff;
	padding: 80px;
}

.light {
	.signup {
		background-color: #fff;
		color: #000;

		&:before, &:after {
			background-color: #fff;
		}

		&__content {
			.list__item:after {
				background-color: #000;
			}
		}

		&__video {
			&__wrap {
				background-color: #dedede;
			}
		}

		.signup__content .form-control {
			color: #000;
		}

		.signup__content .form-control:focus {
			border-color: #000;
		}
	}

	.signup__content .form-group.active .form-group__title {
		color: #a4a4a4;
	}
}


.custom-switch {

	input[type=checkbox]{
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 40px;
		height: 24px;
		display: block;
		border-radius: 20px;
		position: relative;
		border: 2px solid #fff;
	}

	label:after {
		content: '';
		position: absolute;
		top: 2.5px;
		left: 2.5px;
		width: 15px;
		height: 15px;
		background: #fff;
		border-radius: 90px;
		transition: 0.3s;
	}

	input:checked + label {
		border-color: #000;
	}

	input:checked + label:after {
		left: calc(100% - 2.5px);
		transform: translateX(-100%);
		background-color: #111;
	}

	label:active:after {
		width: 30px;
	}
}



#submit {
	margin-left: auto;
	display: table;
	appearance: none;
	width: 100%;
	@include breakpoint(991px) {
		&[disabled] {
			opacity: 0.6;
			color: #999;
		}

		background-color: #fff;
	}
}
.explorer-link {
	width: 100%;
	display: table;
	margin-top: 3rem !important;
	border-color: #ffffff;
	& > .cta__text {
		color: white;
		margin: 0 auto;
		width: 100%;
		display: inline-block;
		text-align: center;
	}
}