//       SUMMARY TABLE     
// =========================
// 1. Global
// =========================


// 1. Global
// =========================

html {
	font-size: 10px;
}

body {
	font-family: 'Haffer', 'Arial', serif;
	font-size: 1.6rem;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

.to-animate {
	overflow: hidden;

	& > * {
		display: block;
		opacity: 0;
		visibility: hidden;
		@include transform(translate3d(0, 15%, 0));
	}

	&.is-inview {
		& > * {
			opacity: 1;
			visibility: visible;
			@include transform(translate3d(0, 0, 0));
			@include transition(all 1.5s $custom);
		}
	}
}


.split-slider {
	perspective: 600px;
	transform-style: preserve-3d;
	display: flex;

	span {
		opacity: 0;
		visibility: hidden;
		@include transform(rotateX(-20deg) translateY(-10vh));
		@include transition(all 0.6s $custom);
		will-change: auto;

		@for $i from 1 to 20 {
			&:nth-child(#{$i}) {
				transition-delay: $i * 0.03s;
			}
		}
	}

	&.animate {
		span {
			opacity: 1;
			visibility: visible;
			@include transform(rotateY(0deg) translateY(0));
		}
	}
}


.split {
	& > span {
		display: inline-block;
		overflow: hidden;
		padding-right: 0.25em;

		span {
			display: block;
			@include transform(translate3d(0, 100%, 0) rotate(5deg));
			@include transition(transform 0.6s $custom);
		}
	}

	&.is-inview,
	&.animate {
		& > span {
			span {
				@include transition(transform 1s $custom);
				@include transform(translate3d(0, 0, 0) rotate(0));
			}

			@for $i from 1 to 15 {
				&:nth-child(#{$i}) span {
					transition-delay: $i * 0.05s;
				}
			}
		}
	}
}

.split-chars {
	overflow: hidden;
  perspective: 1000px;
  line-height: 1.2;
  transform-style: preserve-3d;

	& > span {
		opacity: 0;
		display: inline-block;
		@include transform(translate3d(0, 100%, 0) rotateX(90deg));
		will-change: transform;
		@include transition(all 1s cubic-bezier(.240,.500,0,.99));
	}

	&.is-inview,
	&.animate {
		span {
			opacity: 1;
    	@include transition(all 2s cubic-bezier(.240,.500,0,.99));
			@include transform(translate3d(0, 0, 0) rotateX(0));

			@for $i from 1 to 20 {
			  &:nth-child(#{$i}) { transition-delay: $i * 0.05s; }
			}
		}
	}
}

